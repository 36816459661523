<template>
  <Tree :data="dataTree"></Tree>
  <div>
    <Modal v-model="dzxVisible" title="等值线配置" @on-ok="dzxVisible = false" :mask-closable="false" draggable sticky
      label-width="120px" width="700">
      <Form :model="dzxData">
        <FormItem label="个人风险基准">
          <Input v-model="dzxData.level" placeholder="此处输入个人风险基准">
          <template #append>次/年</template></Input>
        </FormItem>
      </Form>
    </Modal>
    <Modal v-model="AccidentVisible" title="全口径泄漏事件概率" @on-ok="AccidentVisible = false" :mask-closable="false" draggable
      sticky width="700">
      <div>
        <Row>
          <Col span="6">
          <div class="normal-table table-title">
            <span>事件</span>
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-title">
            <span>分布类型</span>
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-title">
            α、(μ)、期望值
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-title table-border-right">
            β、(σ)
          </div>
          </Col>
        </Row>
        <Row>
          <Col span="6">
          <div class="normal-table table-left">喷头喷出</div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Select v-model="form.Accident.noz_po_dist">
              <Option value="beta">Beta</Option>
              <Option value="logn">Lognormal</Option>
              <Option value="expv">期望值</Option>
            </Select>
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Input v-model="form.Accident.noz_po_a" :border="false" placeholder="请输入" />
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content table-border-right">
            <Input v-model="form.Accident.noz_po_b" :border="false" placeholder="请输入" />
          </div>
          </Col>
        </Row>
        <Row>
          <Col span="6">
          <div class="normal-table table-left">喷头关闭失效</div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Select v-model="form.Accident.noz_ftc_dist">
              <Option value="beta">Beta</Option>
              <Option value="logn">Lognormal</Option>
              <Option value="expv">期望值</Option>
            </Select>
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Input v-model="form.Accident.noz_ftc_a" :border="false" placeholder="请输入" />
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content table-border-right">
            <Input v-model="form.Accident.noz_ftc_b" :border="false" placeholder="请输入" />
          </div>
          </Col>
        </Row>
        <Row>
          <Col span="6">
          <div class="normal-table table-left">
            罗曼塞立格拉断阀失效
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Select v-model="form.Accident.coupling_ftc_dist">
              <Option value="beta">Beta</Option>
              <Option value="logn">Lognormal</Option>
              <Option value="expv">期望值</Option>
            </Select>
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Input v-model="form.Accident.coupling_ftc_a" :border="false" placeholder="请输入" />
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content table-border-right">
            <Input v-model="form.Accident.coupling_ftc_b" :border="false" placeholder="请输入" />
          </div>
          </Col>
        </Row>
        <Row>
          <Col span="6">
          <div class="normal-table table-left">
            泄压阀开启失效
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Select v-model="form.Accident.pvalve_fto_dist">
              <Option value="beta">Beta</Option>
              <Option value="logn">Lognormal</Option>
              <Option value="expv">期望值</Option>
            </Select>
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Input v-model="form.Accident.pvalve_fto_a" :border="false" placeholder="请输入" />
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content table-border-right">
            <Input v-model="form.Accident.pvalve_fto_b" :border="false" placeholder="请输入" />
          </div>
          </Col>
        </Row>
        <Row>
          <Col span="6">
          <div class="normal-table table-left">
            手动阀关闭失效
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Select v-model="form.Accident.mvalve_ftc_dist">
              <Option value="beta">Beta</Option>
              <Option value="logn">Lognormal</Option>
              <Option value="expv">期望值</Option>
            </Select>
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Input v-model="form.Accident.mvalve_ftc_a" :border="false" placeholder="请输入" />
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content table-border-right">
            <Input v-model="form.Accident.mvalve_ftc_b" :border="false" placeholder="请输入" />
          </div>
          </Col>
        </Row>
        <Row>
          <Col span="6">
          <div class="normal-table table-left">
            电磁阀关闭失效
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Select v-model="form.Accident.svalve_ftc_dist">
              <Option value="beta">Beta</Option>
              <Option value="logn">Lognormal</Option>
              <Option value="expv">期望值</Option>
            </Select>
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Input v-model="form.Accident.svalve_ftc_a" :border="false" placeholder="请输入" />
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content table-border-right">
            <Input v-model="form.Accident.svalve_ftc_b" :border="false" placeholder="请输入" />
          </div>
          </Col>
        </Row>
        <Row>
          <Col span="6">
          <div class="normal-table table-left">
            电磁阀常规失效
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Select v-model="form.Accident.svalve_ccf_dist">
              <Option value="beta">Beta</Option>
              <Option value="logn">Lognormal</Option>
              <Option value="expv">期望值</Option>
            </Select>
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Input v-model="form.Accident.svalve_ccf_a" :border="false" placeholder="请输入" />
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content table-border-right">
            <Input v-model="form.Accident.svalve_ccf_b" :border="false" placeholder="请输入" />
          </div>
          </Col>
        </Row>
        <Row>
          <Col span="6">
          <div class="normal-table table-left">驶离</div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Select v-model="form.Accident.driveoff_dist">
              <Option value="beta">Beta</Option>
              <Option value="logn">Lognormal</Option>
              <Option value="expv">期望值</Option>
            </Select>
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="padding: 10px">
            <Input v-model="form.Accident.driveoff_a" :border="false" placeholder="请输入" />
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content table-border-right">
            <Input v-model="form.Accident.driveoff_b" :border="false" placeholder="请输入" />
          </div>
          </Col>
        </Row>
        <Row>
          <Col span="6">
          <div class="normal-table table-left" style="border-bottom: 1px solid black">
            充装超压
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="
                                padding: 10px;
                                border-bottom: 1px solid black;
                            ">
            <Select v-model="form.Accident.overp_dist">
              <Option value="beta">Beta</Option>
              <Option value="logn">Lognormal</Option>
              <Option value="expv">期望值</Option>
            </Select>
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content" style="
                                padding: 10px;
                                border-bottom: 1px solid black;
                            ">
            <Input v-model="form.Accident.overp_a" :border="false" placeholder="请输入" />
          </div>
          </Col>
          <Col span="6">
          <div class="normal-table table-content table-border-right" style="border-bottom: 1px solid black">
            <Input v-model="form.Accident.overp_b" :border="false" placeholder="请输入" />
          </div>
          </Col>
        </Row>
        <div>
          车辆数量<Input v-model="form.Vehicles.num_vehicles" placeholder="请输入" />
        </div>
        <div>
          每天每车充装次数<Input v-model="form.Vehicles.daily_fuelings" placeholder="请输入" />
        </div>
        <div>
          每年充装天数<Input v-model="form.Vehicles.vehicle_days" placeholder="请输入" />
        </div>
        <div>
          年充装频率：{{
            form.Vehicles.num_vehicles *
            form.Vehicles.daily_fuelings *
            form.Vehicles.vehicle_days
          }}
        </div>
      </div>
    </Modal>
    <Modal v-model="compressors" title="压缩机泄漏概率" @on-ok="submitComponents" :mask-closable="false" draggable sticky
      width="700">
      <div>
        <div>
          <Row>
            <Col span="6">
            <div class="normal-table table-title">
              <span>泄漏直径</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">
              <span>µ</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">σ</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title table-border-right">
              中位数
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.01%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .compressor_leak_probs[0][0]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .compressor_leak_probs[0][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .compressor_leak_probs[0][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .compressor_leak_probs[1][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .compressor_leak_probs[1][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .compressor_leak_probs[1][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .compressor_leak_probs[2][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .compressor_leak_probs[2][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .compressor_leak_probs[2][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">10%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .compressor_leak_probs[3][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .compressor_leak_probs[3][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .compressor_leak_probs[3][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left table-border-bottom">
              100%
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .compressor_leak_probs[4][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .compressor_leak_probs[4][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right table-border-bottom">
              {{
                Math.exp(
                  form.CompLeakProb
                    .compressor_leak_probs[4][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
    <Modal v-model="vessel" title="储罐泄漏概率" @on-ok="submitComponents" :mask-closable="false" draggable sticky width="700">
      <div>
        <div>
          <Row>
            <Col span="6">
            <div class="normal-table table-title">
              <span>泄漏直径</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">
              <span>µ</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">σ</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title table-border-right">
              中位数
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.01%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .vessel_leak_probs[0][0]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .vessel_leak_probs[0][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .vessel_leak_probs[0][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .vessel_leak_probs[1][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .vessel_leak_probs[1][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .vessel_leak_probs[1][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .vessel_leak_probs[2][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .vessel_leak_probs[2][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .vessel_leak_probs[2][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">10%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .vessel_leak_probs[3][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .vessel_leak_probs[3][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .vessel_leak_probs[3][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left table-border-bottom">
              100%
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .vessel_leak_probs[4][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .vessel_leak_probs[4][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right table-border-bottom">
              {{
                Math.exp(
                  form.CompLeakProb
                    .vessel_leak_probs[4][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
    <Modal v-model="valves" title="阀门泄漏概率" @on-ok="submitComponents" :mask-closable="false" draggable sticky width="700">
      <div>
        <div>
          <Row>
            <Col span="6">
            <div class="normal-table table-title">
              <span>泄漏直径</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">
              <span>µ</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">σ</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title table-border-right">
              中位数
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.01%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.valve_leak_probs[0][0]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.valve_leak_probs[0][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.valve_leak_probs[0][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.valve_leak_probs[1][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.valve_leak_probs[1][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.valve_leak_probs[1][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.valve_leak_probs[2][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.valve_leak_probs[2][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.valve_leak_probs[2][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">10%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.valve_leak_probs[3][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.valve_leak_probs[3][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.valve_leak_probs[3][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left table-border-bottom">
              100%
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb.valve_leak_probs[4][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb.valve_leak_probs[4][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right table-border-bottom">
              {{
                Math.exp(
                  form.CompLeakProb.valve_leak_probs[4][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
    <Modal v-model="instruments" title="设备泄漏概率" @on-ok="submitComponents" :mask-closable="false" draggable sticky
      width="700">
      <div>
        <div>
          <Row>
            <Col span="6">
            <div class="normal-table table-title">
              <span>泄漏直径</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">
              <span>µ</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">σ</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title table-border-right">
              中位数
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.01%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .instrument_leak_probs[0][0]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .instrument_leak_probs[0][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .instrument_leak_probs[0][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .instrument_leak_probs[1][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .instrument_leak_probs[1][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .instrument_leak_probs[1][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .instrument_leak_probs[2][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .instrument_leak_probs[2][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .instrument_leak_probs[2][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">10%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .instrument_leak_probs[3][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .instrument_leak_probs[3][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .instrument_leak_probs[3][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left table-border-bottom">
              100%
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .instrument_leak_probs[4][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .instrument_leak_probs[4][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right table-border-bottom">
              {{
                Math.exp(
                  form.CompLeakProb
                    .instrument_leak_probs[4][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
    <Modal v-model="joints" title="结合处泄漏概率" @on-ok="submitComponents" :mask-closable="false" draggable sticky width="700">
      <div>
        <div>
          <Row>
            <Col span="6">
            <div class="normal-table table-title">
              <span>泄漏直径</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">
              <span>µ</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">σ</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title table-border-right">
              中位数
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.01%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.joint_leak_probs[0][0]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.joint_leak_probs[0][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.joint_leak_probs[0][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.joint_leak_probs[1][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.joint_leak_probs[1][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.joint_leak_probs[1][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.joint_leak_probs[2][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.joint_leak_probs[2][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.joint_leak_probs[2][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">10%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.joint_leak_probs[3][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.joint_leak_probs[3][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.joint_leak_probs[3][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left table-border-bottom">
              100%
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb.joint_leak_probs[4][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb.joint_leak_probs[4][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right table-border-bottom">
              {{
                Math.exp(
                  form.CompLeakProb.joint_leak_probs[4][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
    <Modal v-model="hoses" title="软管泄漏概率" @on-ok="submitComponents" :mask-closable="false" draggable sticky width="700">
      <div>
        <div>
          <Row>
            <Col span="6">
            <div class="normal-table table-title">
              <span>泄漏直径</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">
              <span>µ</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">σ</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title table-border-right">
              中位数
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.01%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.hose_leak_probs[0][0]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.hose_leak_probs[0][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.hose_leak_probs[0][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.hose_leak_probs[1][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.hose_leak_probs[1][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.hose_leak_probs[1][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.hose_leak_probs[2][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.hose_leak_probs[2][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.hose_leak_probs[2][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">10%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.hose_leak_probs[3][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.hose_leak_probs[3][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.hose_leak_probs[3][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left table-border-bottom">
              100%
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb.hose_leak_probs[4][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb.hose_leak_probs[4][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right table-border-bottom">
              {{
                Math.exp(
                  form.CompLeakProb.hose_leak_probs[4][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
    <Modal v-model="pipe_length" title="管道泄漏概率" @on-ok="submitComponents" :mask-closable="false" draggable sticky
      width="700">
      <div>
        <div>
          <Row>
            <Col span="6">
            <div class="normal-table table-title">
              <span>泄漏直径</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">
              <span>µ</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">σ</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title table-border-right">
              中位数
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.01%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.pipe_leak_probs[0][0]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.pipe_leak_probs[0][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.pipe_leak_probs[0][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.pipe_leak_probs[1][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.pipe_leak_probs[1][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.pipe_leak_probs[1][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.pipe_leak_probs[2][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.pipe_leak_probs[2][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.pipe_leak_probs[2][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">10%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.pipe_leak_probs[3][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.pipe_leak_probs[3][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.pipe_leak_probs[3][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left table-border-bottom">
              100%
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb.pipe_leak_probs[4][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb.pipe_leak_probs[4][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right table-border-bottom">
              {{
                Math.exp(
                  form.CompLeakProb.pipe_leak_probs[4][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
    <Modal v-model="filters" title="过滤器泄漏概率" @on-ok="submitComponents" :mask-closable="false" draggable sticky
      width="700">
      <div>
        <div>
          <Row>
            <Col span="6">
            <div class="normal-table table-title">
              <span>泄漏直径</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">
              <span>µ</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">σ</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title table-border-right">
              中位数
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.01%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .filter_leak_probs[0][0]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .filter_leak_probs[0][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .filter_leak_probs[0][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .filter_leak_probs[1][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .filter_leak_probs[1][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .filter_leak_probs[1][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .filter_leak_probs[2][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .filter_leak_probs[2][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .filter_leak_probs[2][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">10%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .filter_leak_probs[3][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .filter_leak_probs[3][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .filter_leak_probs[3][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left table-border-bottom">
              100%
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .filter_leak_probs[4][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .filter_leak_probs[4][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right table-border-bottom">
              {{
                Math.exp(
                  form.CompLeakProb
                    .filter_leak_probs[4][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
    <Modal v-model="flanges" title="法兰泄漏概率" @on-ok="submitComponents" :mask-closable="false" draggable sticky width="700">
      <div>
        <div>
          <Row>
            <Col span="6">
            <div class="normal-table table-title">
              <span>泄漏直径</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">
              <span>µ</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">σ</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title table-border-right">
              中位数
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.01%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .flange_leak_probs[0][0]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .flange_leak_probs[0][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .flange_leak_probs[0][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .flange_leak_probs[1][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .flange_leak_probs[1][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .flange_leak_probs[1][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .flange_leak_probs[2][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .flange_leak_probs[2][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .flange_leak_probs[2][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">10%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .flange_leak_probs[3][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .flange_leak_probs[3][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .flange_leak_probs[3][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left table-border-bottom">
              100%
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .flange_leak_probs[4][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .flange_leak_probs[4][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right table-border-bottom">
              {{
                Math.exp(
                  form.CompLeakProb
                    .flange_leak_probs[4][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
    <Modal v-model="exchangers" title="热交换器泄漏概率" @on-ok="submitComponents" :mask-closable="false" draggable sticky
      width="700">
      <div>
        <div>
          <Row>
            <Col span="6">
            <div class="normal-table table-title">
              <span>泄漏直径</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">
              <span>µ</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">σ</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title table-border-right">
              中位数
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.01%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .exchanger_leak_probs[0][0]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .exchanger_leak_probs[0][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .exchanger_leak_probs[0][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .exchanger_leak_probs[1][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .exchanger_leak_probs[1][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .exchanger_leak_probs[1][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .exchanger_leak_probs[2][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .exchanger_leak_probs[2][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .exchanger_leak_probs[2][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">10%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .exchanger_leak_probs[3][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .exchanger_leak_probs[3][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .exchanger_leak_probs[3][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left table-border-bottom">
              100%
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .exchanger_leak_probs[4][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .exchanger_leak_probs[4][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right table-border-bottom">
              {{
                Math.exp(
                  form.CompLeakProb
                    .exchanger_leak_probs[4][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
    <Modal v-model="vaporizers" title="冷凝器泄漏概率" @on-ok="submitComponents" :mask-closable="false" draggable sticky
      width="700">
      <div>
        <div>
          <Row>
            <Col span="6">
            <div class="normal-table table-title">
              <span>泄漏直径</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">
              <span>µ</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">σ</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title table-border-right">
              中位数
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.01%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .vaporizer_leak_probs[0][0]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .vaporizer_leak_probs[0][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .vaporizer_leak_probs[0][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .vaporizer_leak_probs[1][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .vaporizer_leak_probs[1][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .vaporizer_leak_probs[1][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .vaporizer_leak_probs[2][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .vaporizer_leak_probs[2][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .vaporizer_leak_probs[2][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">10%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .vaporizer_leak_probs[3][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .vaporizer_leak_probs[3][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb
                    .vaporizer_leak_probs[3][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left table-border-bottom">
              100%
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                    .vaporizer_leak_probs[4][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb
                .vaporizer_leak_probs[4][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right table-border-bottom">
              {{
                Math.exp(
                  form.CompLeakProb
                    .vaporizer_leak_probs[4][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
    <Modal v-model="arms" title="装卸臂泄漏概率" @on-ok="submitComponents" :mask-closable="false" draggable sticky width="700">
      <div>
        <div>
          <Row>
            <Col span="6">
            <div class="normal-table table-title">
              <span>泄漏直径</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">
              <span>µ</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title">σ</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title table-border-right">
              中位数
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.01%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.arm_leak_probs[0][0]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.arm_leak_probs[0][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.arm_leak_probs[0][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">0.1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.arm_leak_probs[1][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.arm_leak_probs[1][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.arm_leak_probs[1][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.arm_leak_probs[2][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.arm_leak_probs[2][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.arm_leak_probs[2][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left">10%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.arm_leak_probs[3][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="padding: 10px">
              <Input v-model="form.CompLeakProb.arm_leak_probs[3][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right">
              {{
                Math.exp(
                  form.CompLeakProb.arm_leak_probs[3][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
          <Row>
            <Col span="6">
            <div class="normal-table table-left table-border-bottom">
              100%
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb.arm_leak_probs[4][0]
                  " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="padding: 10px">
              <Input v-model="form.CompLeakProb.arm_leak_probs[4][1]
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-right table-border-bottom">
              {{
                Math.exp(
                  form.CompLeakProb.arm_leak_probs[4][0]
                ).toFixed(10)
              }}
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
    <Modal v-model="cloudVisible" title="风频与泄漏方向概率" :loading="cloudLoading" @on-ok="submitCloud" :mask-closable="false"
      draggable sticky width="700">
      <el-form :model="form.CompassRose" ref="cloudFormRef" :rules="cloudRules" :label-width="100">
        <el-form-item label="初始角度" prop="initAngle">
          <el-input v-model="form.CompassRose.initAngle" placeholder="请输入初始角度">
            <template #append>°</template>
          </el-input>
          <span>0°对应正东方向，顺时针为正方向</span>
        </el-form-item>
        <el-form-item label="下风风频" prop="frequencies">
          <el-input v-model="form.CompassRose.frequencies" placeholder="请输入下风风频" />
          <span>请至少输入8个值，以“,”号分割，且和为1</span>
        </el-form-item>
      </el-form>
    </Modal>
    <Modal v-model="setting" title="手动设置" @on-ok="submitSetting" :mask-closable="false" draggable sticky width="700">
      <div>
        <div align="center">
          <Row>
            <Col span="6">
            </Col>
            <Col span="6">
            <div class="normal-table table-title">
              <span>泄漏直径</span>
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-title" style="border-right: 1px solid black">
              <span>值</span>
            </div>
            </Col>

            <Col span="6">
            </Col>
          </Row>
          <Row>
            <Col span="6">
            </Col>
            <Col span="6">
            <div class="normal-table table-left">0.01%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="
                                    padding: 10px;
                                    border-right: 1px solid black;
                                ">
              <Input v-model="form.Pipe_ManualLeak.rel_freq_000d01
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>

            <Col span="6">
            </Col>
          </Row>
          <Row>
            <Col span="6">
            </Col>
            <Col span="6">
            <div class="normal-table table-left">0.1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="
                                    padding: 10px;
                                    border-right: 1px solid black;
                                ">
              <Input v-model="form.Pipe_ManualLeak.rel_freq_000d10
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>

            <Col span="6">
            </Col>
          </Row>
          <Row>
            <Col span="6">
            </Col>
            <Col span="6">
            <div class="normal-table table-left">1%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="
                                    padding: 10px;
                                    border-right: 1px solid black;
                                ">
              <Input v-model="form.Pipe_ManualLeak.rel_freq_001d00
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>

            <Col span="6">
            </Col>
          </Row>
          <Row>
            <Col span="6">
            </Col>
            <Col span="6">
            <div class="normal-table table-left">10%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="
                                    padding: 10px;
                                    border-right: 1px solid black;
                                ">
              <Input v-model="form.Pipe_ManualLeak.rel_freq_010d00
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>

            <Col span="6">
            </Col>
          </Row>
          <Row>
            <Col span="6">
            </Col>
            <Col span="6">
            <div class="normal-table table-left">100%</div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content" style="
                                    padding: 10px;
                                    border-right: 1px solid black;
                                ">
              <Input v-model="form.Pipe_ManualLeak.rel_freq_100d00
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>

            <Col span="6">
            </Col>
          </Row>
          <Row>
            <Col span="6">
            </Col>
            <Col span="6">
            <div class="normal-table table-left table-border-bottom">
              100%（意外）
            </div>
            </Col>
            <Col span="6">
            <div class="normal-table table-content table-border-bottom" style="
                                    padding: 10px;
                                    border-right: 1px solid black;
                                ">
              <Input v-model="form.Pipe_ManualLeak
                .fueling_fail_freq_override
                " :border="false" placeholder="请输入µ值" />
            </div>
            </Col>

            <Col span="6">
            </Col>
          </Row>
          <Row>
            <Col span="6">
            </Col>
            <Col span="6">
            <div class="table-left" style="
                                    border-left: 1px solid black;
                                    border-bottom: 1px solid black;
                                ">
              泄漏探测概率
            </div>
            </Col>
            <Col span="6">
            <div style="
                                    text-align: center;
                                    padding: 10px;
                                    font-size: 16px;
                                    font-weight: 500;
                                    border-right: 1px solid black;
                                    border-bottom: 1px solid black;
                                    border-left: 1px solid black;
                                    height: 50px;
                                ">
              <Input v-model="form.Pipe_ManualLeak.detection_credit
                " :border="false" />
            </div>
            </Col>
            <Col span="6">
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
    <Modal v-model="ignition" title="点火概率" @on-ok="submitIgnition" :mask-closable="false" draggable sticky width="800">
      <div>
        <el-select v-model="modelFire" placeholder="请选择模型" style="width: 100%;margin-bottom: 20px;"
          @change="chooseFireModel">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <Row v-if="false">
          <Col span="8">
          <Card>
            <template #title> 点火阈值列表 (kg/s) </template>

            <div v-for="(item, index) in form.Ignition
              .ign_thresholds" :key="index">
              <Row>
                <Col span="18"><Input :border="false" v-model="form.Ignition.ign_thresholds[
                  index
                ]
                  " placeholder="请输入点火阈值" />
                </Col>
                <Col span="6">
                <Button @click="delete_ign(index)" type="text">删除</Button></Col>
              </Row>
            </div>

            <Button @click="add_ign_thresholds()" long type="success">添加阈值</Button>
          </Card>
          </Col>
          <Col span="1">
          </Col>
          <Col span="15">
          <Table :columns="columnsIgn" :data="dataIgn" border></Table>
          </Col>
        </Row>
      </div>
    </Modal>
    <Modal v-model="system_fire" title="喷射火" @on-ok="submitSetModel" :mask-closable="false" draggable sticky width="700"
      class-name="vertical-center-modal">
      <Form :model="form" :label-width="100">
        <FormItem label="喷嘴模型">
          <Select v-model="form.System.nozzle_model">
            <Option value="yuce">Yuceil/Otugen</Option>
            <Option value="birc">Birch</Option>
            <Option value="bir2">Birch2</Option>
            <Option value="ewan">Ewan/Moodie</Option>
            <Option value="molk">Molkov</Option>
          </Select>
        </FormItem>
        <FormItem label="probit模型">
          <Select v-model="form.System.probit_thermal_id">
            <Option value="eise">eise</Option>
            <Option value="tsao">tsao</Option>
            <Option value="tno">tno</Option>
            <Option value="lees">lees</Option>
          </Select>
        </FormItem>
        <FormItem label="暴露时间">
          <Input v-model="form.System.exposure_time" type="number">
          <template #append>s</template></Input>
        </FormItem>
      </Form>
    </Modal>
    <Modal v-model="system_boom" title="爆炸" @on-ok="submitCapacityParam" :mask-closable="false" draggable sticky
      width="800">
      <Form :model="form" label-position="right" :label-width="100">
        <FormItem label="爆炸模型名称">
          <Select v-model="form.System.explosion_model" @on-change="changeExplosionmModel">
            <Option value="bst">bst</Option>
            <Option value="tnt">tnt</Option>
            <Option value="bauwens">bauwens</Option>
            <!-- <Option value="head">head</Option> -->
          </Select>
        </FormItem>
        <FormItem label="爆炸伤害模型">
          <Select v-model="form.System.probit_explosion_id">
            <Option value="coll">coll</Option>
            <Option value="leis">leis</Option>
            <Option value="lhse">lhse</Option>
            <Option value="head">head</Option>
          </Select>
        </FormItem>
        <FormItem label="BST爆炸模型火焰速度" v-if="form.System.explosion_model === 'bst'">
          <Input v-model="form.System.bst_method_Flamspeed" />
        </FormItem>
        <FormItem label="TNT爆炸模式系数" v-if="form.System.explosion_model === 'tnt'">
          <Input v-model="form.System.tnt_method_Factor" />
        </FormItem>
      </Form>
      <!-- <div>
              <Row>
                  <Col span="4">
                  <div class="table-title">Variable</div>
                  </Col>
                  <Col span="4">
                  <div class="table-title">0.01% Leak</div>
                  </Col>
                  <Col span="4">
                  <div class="table-title">0.1% Leak</div>
                  </Col>
                  <Col span="4">
                  <div class="table-title">1.0% Leak</div>
                  </Col>
                  <Col span="4">
                  <div class="table-title">10% Leak</div>
                  </Col>
                  <Col span="4">
                  <div class="table-title">100% Leak</div>
                  </Col>
              </Row>
              <Row style="padding: 10px; border-bottom: 1px solid black">
                  <Col span="4">
                  <div class="table-title-left">
                      Peak Overpressure
                  </div>
                  </Col>
                  <Col span="4">
                  <div>
                      <Input v-model="form.Overpressure.peak_overp_list[0]" placeholder="请输入相应值" size="small"
                          :border="false" />
                  </div>
                  </Col>
                  <Col span="4">
                  <div>
                      <Input v-model="form.Overpressure.peak_overp_list[1]" placeholder="请输入相应值" size="small"
                          :border="false" />
                  </div>
                  </Col>
                  <Col span="4">
                  <div>
                      <Input v-model="form.Overpressure.peak_overp_list[2]" placeholder="请输入相应值" size="small"
                          :border="false" />
                  </div>
                  </Col>
                  <Col span="4">
                  <div>
                      <Input v-model="form.Overpressure.peak_overp_list[3]" placeholder="请输入相应值" size="small"
                          :border="false" />
                  </div>
                  </Col>
                  <Col span="4">
                  <div>
                      <Input v-model="form.Overpressure.peak_overp_list[4]" placeholder="请输入相应值" size="small"
                          :border="false" />
                  </div>
                  </Col>
              </Row>
              <Row style="padding: 10px; border-bottom: 1px solid black">
                  <Col span="4">
                  <div class="table-title-left">impulse</div>
                  </Col>
                  <Col span="4">
                  <div>
                      <Input v-model="form.Overpressure.overp_impulse_list[0]
                          " placeholder="请输入相应值" size="small" :border="false" />
                  </div>
                  </Col>
                  <Col span="4">
                  <div>
                      <Input v-model="form.Overpressure.overp_impulse_list[1]
                          " placeholder="请输入相应值" size="small" :border="false" />
                  </div>
                  </Col>
                  <Col span="4">
                  <div>
                      <Input v-model="form.Overpressure.overp_impulse_list[2]
                          " placeholder="请输入相应值" size="small" :border="false" />
                  </div>
                  </Col>
                  <Col span="4">
                  <div>
                      <Input v-model="form.Overpressure.overp_impulse_list[3]
                          " placeholder="请输入相应值" size="small" :border="false" />
                  </div>
                  </Col>
                  <Col span="4">
                  <div>
                      <Input v-model="form.Overpressure.overp_impulse_list[4]
                          " placeholder="请输入相应值" size="small" :border="false" />
                  </div>
                  </Col>
              </Row>
          </div> -->
    </Modal>
    <Modal v-model="pipe_param" title="管道参数" @on-ok="submitPipe" :mask-closable="false" draggable sticky width="700">
      <Form :model="form" label-position="right" :label-width="100">
        <FormItem label="流体相态">
          <Select v-model="form.Fluid.rel_phase">
            <Option value="none">气体</Option>
            <Option value="gas">饱和气体</Option>
            <Option value="liquid">饱和液体</Option>
          </Select>
        </FormItem>
        <FormItem label="管道外径" type="number">
          <Input v-model="form.Pipe_ManualLeak.pipe_outer_diam">
          <template #append>m</template></Input>
        </FormItem>
        <FormItem label="管壁厚度" type="number">
          <Input v-model="form.Pipe_ManualLeak.pipe_thickness"><template #append>m</template>
          </Input>
        </FormItem>
        <FormItem label="流体压力" type="number">
          <Input v-model="form.Fluid.rel_pres">
          <template #append>Pa</template></Input>
        </FormItem>
        <FormItem label="流体温度" v-if="form.Fluid.rel_phase === 'none'" type="number">
          <Input v-model="form.Fluid.rel_temp"><template #append>K</template>
          </Input>
        </FormItem>
      </Form>
    </Modal>
    <Modal v-model="amb_param" title="环境参数" @on-ok="submitCapacityParam" :mask-closable="false" draggable sticky
      width="700">
      <Form :model="form" label-position="right" :label-width="100">
        <FormItem label="环境温度" type="number">
          <Input v-model="form.Fluid.amb_temp">
          <template #append>K</template></Input>
        </FormItem>
        <FormItem label="环境压力" type="number">
          <Input v-model="form.Fluid.amb_pres"><template #append>Pa</template>
          </Input>
        </FormItem>
      </Form>
    </Modal>
    <Modal v-model="XYZ" title="场景坐标设置" @on-ok="submitCapacityParam" :mask-closable="false" draggable sticky width="300">
      <Form :model="form" label-position="right" :label-width="50">
        <FormItem label="长" type="number">
          <Input v-model="form.Scene.X"> </Input>
        </FormItem>
        <FormItem label="宽" type="number">
          <Input v-model="form.Scene.Y"> </Input>
        </FormItem>
        <FormItem label="高" type="number">
          <Input v-model="form.Scene.Z"> </Input>
        </FormItem>
      </Form>
    </Modal>
    <Modal v-model="people_position" title="人员分布情况" @on-ok="submitCapacityParam" :mask-closable="false" draggable sticky
      width="1000">
      <Form :model="form" label-position="right" :label-width="100" inline>
        <FormItem label="随机数种子" type="number">
          <Input v-model="form.System.rand_seed"> </Input>
        </FormItem>
        <FormItem label="模型忽略半径" type="number">
          <Input v-model="form.System.excl_radius"> </Input>
        </FormItem>
      </Form>
      <Table :columns="columnsPeople" :data="dataPeople"></Table>
    </Modal>
    <Modal v-model="isModal" title="编辑地图尺寸" @on-cancel="isModal = false" @on-ok="ok">
      <div style="margin-bottom: 20px">
        图片宽度（px）：
        <Input size="large" placeholder="图片的长度" disabled v-model="mapValue.value1" />
      </div>
      <div style="margin-bottom: 20px">
        图片的长度（米）:
        <Input size="large" placeholder="图片的长度" v-model="mapValue.value2" />
      </div>
      <div>
        网格大小（米）:
        <Input size="large" placeholder="图片的长度" v-model="mapValue.value4" />
      </div>
      <div>
        单位像素代表的实际距离尺寸:
        <Input size="large" placeholder="图片的长度" disabled v-model="mapValue.value3" />
      </div>
    </Modal>
    <Modal v-model="isModal1" title="编辑地图尺寸" @on-cancel="isModal1 = false" @on-ok="ok1">
      <div style="margin-bottom: 20px">
        该区域所占网格数：
        <Input size="large" placeholder="该区域所占网格数" disabled v-model="mapValue1.value1" />
      </div>
      <div style="margin-bottom: 20px">
        该区域人数:
        <Input @change="inputChange" size="large" placeholder="该区域人数" v-model="mapValue1.value2" />
      </div>
      <div>
        单位网格下人员密度:
        <Input size="large" placeholder="单位网格下人员密度" disabled v-model="mapValue1.value3" />
      </div>
    </Modal>
    <Modal v-model="isModal2" title="泄漏点" width="1000px" @on-cancel="isModal2 = false" @on-ok="ok2">
      <div class="modal-row">
        <div class="modal-col">
          压缩机
          <Input size="large" placeholder="压缩机" v-model="mapValue3.Components.num_compressors">
          <template #append>个</template>
          </Input>
        </div>
        <div class="modal-col">
          阀门
          <Input size="large" placeholder="阀门" v-model="mapValue3.Components.num_valves">
          <template #append>个</template>
          </Input>
        </div>
      </div>

      <div class="modal-row">
        <div class="modal-col">
          储罐
          <Input size="large" placeholder="储罐" v-model="mapValue3.Components.num_vessels">
          <template #append>个</template>
          </Input>
        </div>
        <div class="modal-col">
          设备
          <Input size="large" placeholder="设备" v-model="mapValue3.Components.num_instruments">
          <template #append>个</template>
          </Input>
        </div>
      </div>

      <div class="modal-row">
        <div class="modal-col">
          结合处
          <Input size="large" placeholder="结合处" v-model="mapValue3.Components.num_joints">
          <template #append>个</template>
          </Input>
        </div>
        <div class="modal-col">
          软管
          <Input size="large" placeholder="软管" v-model="mapValue3.Components.num_hoses">
          <template #append>个</template>
          </Input>
        </div>
      </div>
      <div class="modal-row">
        <div class="modal-col">
          管道长度
          <Input size="large" placeholder="管道长度" v-model="mapValue3.Components.pipe_length">
          <template #append>米</template>
          </Input>
        </div>
        <div class="modal-col">
          过滤器
          <Input size="large" placeholder="过滤器" v-model="mapValue3.Components.num_filters">
          <template #append>个</template>
          </Input>
        </div>
      </div>
      <div class="modal-row">
        <div class="modal-col">
          法兰
          <Input size="large" placeholder="法兰" v-model="mapValue3.Components.num_flanges">
          <template #append>个</template>
          </Input>
        </div>
        <div class="modal-col">
          热交换器
          <Input size="large" placeholder="热交换器" v-model="mapValue3.Components.num_exchangers">
          <template #append>个</template>
          </Input>
        </div>
      </div>

      <div class="modal-row">
        <div class="modal-col">
          冷凝器
          <Input size="large" placeholder="冷凝器" v-model="mapValue3.Components.num_vaporizers">
          <template #append>个</template>
          </Input>
        </div>
        <div class="modal-col">
          装卸器
          <Input size="large" placeholder="装卸器" v-model="mapValue3.Components.num_arms">
          <template #append>个</template>
          </Input>
        </div>
      </div>

      <div class="modal-row">
        <div class="modal-col">
          附件1
          <Input size="large" placeholder="附件1" v-model="mapValue3.Components.num_extra_comp1">
          <template #append>个</template>
          </Input>
        </div>
        <div class="modal-col">
          附件2
          <Input size="large" placeholder="附件2" v-model="mapValue3.Components.num_extra_comp2">
          <template #append>个</template>
          </Input>
        </div>
      </div>
      <div class="modal-row">
        <div class="modal-col">
          泄漏高度
          <Input size="large" placeholder="附件1" v-model="mapValue3.YAvrg">
          <template #append>米</template>
          </Input>
        </div>
        <!-- <div class="modal-col">
          泄漏角度
          <Input size="large" placeholder="附件2" v-model="mapValue3.AngleTemp" @on-change="changeAngleTemp">
          <template #append>°</template>
          </Input>
        </div> -->
      </div>
    </Modal>
  </div>
</template>
<script>
import { ref } from "vue";
import global from "../assets/global.vue";
import { resolveComponent } from "vue";
import { bus } from "../main";
export default {
  name: "pllTree",
  data() {
    const frequenciesValidator = (rule, value, callback) => {
      const arr = value.split(',')
      console.log(arr, 'cccc')
      if (arr.length < 8) {
        callback(new Error('请输入至少8个数值且总和等于1'))
        return
      }
      const sum = arr.reduce((pre, item) => {
        pre = pre + Number(item) * 10000
        return pre
      }, 0)
      console.log(sum, 2222)
      if (sum !== 10000) {
        callback(new Error('请输入至少8个数值且总和等于1'))
        return
      }
      callback()
    }
    return {
      // form.CompassRose:global.pllJSON.CompassRose,
      showFireList: false,
      modelFire: '',
      options: [
        {
          value: "dnv",
          label: "Hyram模型",
        },
        {
          value: "dsb",
          label: "Hyex模型",
        }
      ],
      dzxVisible: false, //等值线
      dzxData: {
        level: "0.000003 0.00001 0.00003",
        rolevel: "1.577 4.732 25.237",
        ovelevel: "5000 16000 70000",
      },
      lists: [],
      isModal2: false,
      mapValue: {
        value1: "",
        value2: "",
        value3: "",
        value4: "",
      },
      mapValue1: {
        value1: "",
        value2: "",
        value3: "",
      },
      mapValue3: {
        Components: {
          pipe_length: 20,
          num_compressors: 0,
          num_vessels: 0,
          num_valves: 5,
          num_instruments: 3,
          num_joints: 35,
          num_hoses: 1,
          num_filters: 0,
          num_flanges: 0,
          num_exchangers: 0,
          num_vaporizers: 0,
          num_arms: 0,
          num_extra_comp1: 0,
          num_extra_comp2: 0,
        },
        YAvrg: 1,
        Angle: 0,
        AngleTemp: 0,
      },
      isModal: false,
      isModal1: false,
      columnsPeople: [
        {
          title: "人员数量",
          key: "count",
        },
        {
          title: "X轴分布类型",
          key: "xdistr",
        },
        {
          title: "分布系数A",
          key: "xa",
        },
        {
          title: "分布系数B",
          key: "xb",
        },
        {
          title: "Y轴分布类型",
          key: "ydistr",
        },
        {
          title: "分布系数A",
          key: "ya",
        },
        {
          title: "分布系数B",
          key: "yb",
        },
        {
          title: "Z轴分布类型",
          key: "zdistr",
        },
        {
          title: "分布系数A",
          key: "za",
        },
        {
          title: "分布系数B",
          key: "zb",
        },
        {
          title: "每人每年暴露时间",
          key: "hours",
        },
      ],
      dataPeople: [
        {
          count: 9,
          descrip: "人员组1",
          xdistr: "dete",
          zdistr: "dete",
          ydistr: "unif",
          xa: 1,
          xb: 20,
          za: 0,
          zb: 0,
          ya: 1,
          yb: 12,
          hours: 2000,
        },
      ],
      columnsIgn: [
        {
          title: "泄漏流率 (kg/s)",
          key: "ign_thresholds",
        },
        {
          title: "立即点火概率",
          key: "immed_ign_probs",
        },
        {
          title: "延迟点火概率",
          key: "delayed_ign_probs",
        },
      ],
      dataIgn: [
        {
          ign_thresholds: "<0.125",
          immed_ign_probs: 0.008,
          delayed_ign_probs: 0.004,
        },
        {
          ign_thresholds: "0.125~6.25",
          immed_ign_probs: 0.053,
          delayed_ign_probs: 0.027,
        },
        {
          ign_thresholds: "≥6.25",
          immed_ign_probs: 0.23,
          delayed_ign_probs: 0.12,
        },
      ],
      ////////
      components: false,
      //组件泄漏概率
      compressors: false, //压缩机
      vessel: false, //储罐
      valves: false, //阀门
      instruments: false, //设备
      joints: false, //结合处
      hoses: false, //软管
      pipe_length: false, //管长
      filters: false, //过滤器
      flanges: false, //法兰
      exchangers: false, //热交换器
      vaporizers: false, //冷凝器
      arms: false, //装卸臂
      ////////
      setting: false, //手动设置
      ignition: false, //点火概率
      system_fire: false,
      system_boom: false,
      pipe_param: false,
      amb_param: false,
      XYZ: false,
      people_position: false,
      ////////
      id: 0,
      form: JSON.parse(JSON.stringify(global.pllJSON)),
      //storeForm: global.pllTempJSON,
      dataTree: [
        {
          render: (h, { root, node, data }) => {
            return h("span", {}, [
              h("span", [
                h(resolveComponent("Icon"), {
                  type: "logo-buffer",
                  style: {
                    marginRight: "4px",
                  },
                }),
                h("span", data.title),
              ]),
            ]);
          },
          title: "概率",
          expand: true,
          children: [
            {
              title: "组件",
              expand: true,
              render: (h, { root, node, data }) => {
                return h(
                  "span",
                  {
                    // onClick: () => (this.components = true),
                  },
                  [
                    h("span", [
                      h(resolveComponent("Icon"), {
                        type: "ios-construct",
                        style: {
                          marginRight: "4px",
                        },
                      }),
                      h("span", data.title),
                    ]),
                  ]
                );
              },
              children: [
                {
                  title: "压缩机",
                  expand: true,
                  render: (h, { root, node, data }) => {
                    return h(
                      "div",
                      {
                        onClick: () =>
                          (this.compressors = true),

                        style: {
                          width: "125px",
                        },
                      },
                      [
                        h("span", [
                          h("Icon", {
                            props: {
                              type: "",
                            },
                            style: {
                              marginLeft: "8px",
                              marginRight: "4px",
                            },
                          }),
                          h("span", data.title),
                          // h(
                          //     "span",
                          //     {
                          //         style: {
                          //             float: "right",
                          //             color: "grey",
                          //         },
                          //     },
                          //     this.form.Components
                          //         .num_compressors +
                          //     "个"
                          // ),
                        ]),
                      ]
                    );
                  },
                },
                {
                  title: "储罐",
                  expand: true,
                  render: (h, { root, node, data }) => {
                    return h(
                      "div",
                      {
                        onClick: () =>
                          (this.vessel = true),
                        style: {
                          width: "125px",
                        },
                      },
                      [
                        h("span", [
                          h("Icon", {
                            props: {
                              type: "",
                            },
                            style: {
                              marginLeft: "8px",
                              marginRight: "4px",
                            },
                          }),
                          h("span", data.title),
                          // h(
                          //     "span",
                          //     {
                          //         style: {
                          //             float: "right",
                          //             color: "grey",
                          //         },
                          //     },
                          //     this.form.Components
                          //         .num_vessels + "个"
                          // ),
                        ]),
                      ]
                    );
                  },
                },
                {
                  title: "阀门",
                  expand: true,
                  render: (h, { root, node, data }) => {
                    return h(
                      "div",
                      {
                        onClick: () =>
                          (this.valves = true),
                        style: {
                          width: "125px",
                        },
                      },
                      [
                        h("span", [
                          h("Icon", {
                            props: {
                              type: "",
                            },
                            style: {
                              marginLeft: "8px",
                              marginRight: "4px",
                            },
                          }),
                          h("span", data.title),
                          // h(
                          //     "span",
                          //     {
                          //         style: {
                          //             float: "right",
                          //             color: "grey",
                          //         },
                          //     },
                          //     this.form.Components
                          //         .num_valves + "个"
                          // ),
                        ]),
                      ]
                    );
                  },
                },
                {
                  title: "设备",
                  expand: true,
                  render: (h, { root, node, data }) => {
                    return h(
                      "div",
                      {
                        onClick: () =>
                          (this.instruments = true),
                        style: {
                          width: "125px",
                        },
                      },
                      [
                        h("span", [
                          h("Icon", {
                            props: {
                              type: "",
                            },
                            style: {
                              marginLeft: "8px",
                              marginRight: "4px",
                            },
                          }),
                          h("span", data.title),
                          // h(
                          //     "span",
                          //     {
                          //         style: {
                          //             float: "right",
                          //             color: "grey",
                          //         },
                          //     },
                          //     this.form.Components
                          //         .num_instruments +
                          //     "个"
                          // ),
                        ]),
                      ]
                    );
                  },
                },

                {
                  title: "结合处",
                  expand: true,
                  render: (h, { root, node, data }) => {
                    return h(
                      "div",
                      {
                        onClick: () =>
                          (this.joints = true),
                        style: {
                          width: "125px",
                        },
                      },
                      [
                        h("span", [
                          h("Icon", {
                            props: {
                              type: "",
                            },
                            style: {
                              marginLeft: "8px",
                              marginRight: "4px",
                            },
                          }),
                          h("span", data.title),
                          // h(
                          //     "span",
                          //     {
                          //         style: {
                          //             float: "right",
                          //             color: "grey",
                          //         },
                          //     },
                          //     this.form.Components
                          //         .num_joints + "个"
                          // ),
                        ]),
                      ]
                    );
                  },
                },
                {
                  title: "软管",
                  expand: true,
                  render: (h, { root, node, data }) => {
                    return h(
                      "div",
                      {
                        onClick: () =>
                          (this.hoses = true),
                        style: {
                          width: "125px",
                        },
                      },
                      [
                        h("span", [
                          h("Icon", {
                            props: {
                              type: "",
                            },
                            style: {
                              marginLeft: "8px",
                              marginRight: "4px",
                            },
                          }),
                          h("span", data.title),
                          // h(
                          //     "span",
                          //     {
                          //         style: {
                          //             float: "right",
                          //             color: "grey",
                          //         },
                          //     },
                          //     this.form.Components
                          //         .num_hoses + "个"
                          // ),
                        ]),
                      ]
                    );
                  },
                },
                {
                  title: "管道",
                  expand: true,
                  render: (h, { root, node, data }) => {
                    return h(
                      "div",
                      {
                        onClick: () =>
                          (this.pipe_length = true),
                        style: {
                          width: "125px",
                        },
                      },
                      [
                        h("span", [
                          h("Icon", {
                            props: {
                              type: "",
                            },
                            style: {
                              marginLeft: "8px",
                              marginRight: "4px",
                            },
                          }),
                          h("span", data.title),
                          // h(
                          //     "span",
                          //     {
                          //         style: {
                          //             float: "right",
                          //             color: "grey",
                          //         },
                          //     },
                          //     this.form.Components
                          //         .pipe_length + "个"
                          // ),
                        ]),
                      ]
                    );
                  },
                },
                {
                  title: "过滤器",
                  expand: true,
                  render: (h, { root, node, data }) => {
                    return h(
                      "div",
                      {
                        onClick: () =>
                          (this.filters = true),

                        style: {
                          width: "125px",
                        },
                      },
                      [
                        h("span", [
                          h("Icon", {
                            props: {
                              type: "",
                            },
                            style: {
                              marginLeft: "8px",
                              marginRight: "4px",
                            },
                          }),
                          h("span", data.title),
                          // h(
                          //     "span",
                          //     {
                          //         style: {
                          //             float: "right",
                          //             color: "grey",
                          //         },
                          //     },
                          //     this.form.Components
                          //         .num_filters + "个"
                          // ),
                        ]),
                      ]
                    );
                  },
                },
                {
                  title: "法兰",
                  expand: true,
                  render: (h, { root, node, data }) => {
                    return h(
                      "div",
                      {
                        onClick: () =>
                          (this.flanges = true),

                        style: {
                          width: "125px",
                        },
                      },
                      [
                        h("span", [
                          h("Icon", {
                            props: {
                              type: "",
                            },
                            style: {
                              marginLeft: "8px",
                              marginRight: "4px",
                            },
                          }),
                          h("span", data.title),
                          // h(
                          //     "span",
                          //     {
                          //         style: {
                          //             float: "right",
                          //             color: "grey",
                          //         },
                          //     },
                          //     this.form.Components
                          //         .num_flanges + "个"
                          // ),
                        ]),
                      ]
                    );
                  },
                },
                {
                  title: "热交换器",
                  expand: true,
                  render: (h, { root, node, data }) => {
                    return h(
                      "div",
                      {
                        onClick: () =>
                          (this.exchangers = true),
                        style: {
                          width: "125px",
                        },
                      },
                      [
                        h("span", [
                          h("Icon", {
                            props: {
                              type: "",
                            },
                            style: {
                              marginLeft: "8px",
                              marginRight: "4px",
                            },
                          }),
                          h("span", data.title),
                          // h(
                          //     "span",
                          //     {
                          //         style: {
                          //             float: "right",
                          //             color: "grey",
                          //         },
                          //     },
                          //     this.form.Components
                          //         .num_exchangers +
                          //     "个"
                          // ),
                        ]),
                      ]
                    );
                  },
                },
                {
                  title: "冷凝器",
                  expand: true,
                  render: (h, { root, node, data }) => {
                    return h(
                      "div",
                      {
                        onClick: () =>
                          (this.vaporizers = true),
                        style: {
                          width: "125px",
                        },
                      },
                      [
                        h("span", [
                          h("Icon", {
                            props: {
                              type: "",
                            },
                            style: {
                              marginLeft: "8px",
                              marginRight: "4px",
                            },
                          }),
                          h("span", data.title),
                          // h(
                          //     "span",
                          //     {
                          //         style: {
                          //             float: "right",
                          //             color: "grey",
                          //         },
                          //     },
                          //     this.form.Components
                          //         .num_vaporizers +
                          //     "个"
                          // ),
                        ]),
                      ]
                    );
                  },
                },
                {
                  title: "装卸臂",
                  expand: true,
                  render: (h, { root, node, data }) => {
                    return h(
                      "div",
                      {
                        onClick: () =>
                          (this.arms = true),

                        style: {
                          width: "125px",
                        },
                      },
                      [
                        h("span", [
                          h("Icon", {
                            props: {
                              type: "",
                            },
                            style: {
                              marginLeft: "8px",
                              marginRight: "4px",
                            },
                          }),
                          h("span", data.title),
                          // h(
                          //     "span",
                          //     {
                          //         style: {
                          //             float: "right",
                          //             color: "grey",
                          //         },
                          //     },
                          //     this.form.Components
                          //         .num_arms + "个"
                          // ),
                        ]),
                      ]
                    );
                  },
                },
                {
                  title: "附件1",
                  expand: true,
                  render: (h, { root, node, data }) => {
                    return h(
                      "div",
                      {
                        onClick: () =>
                          (this.output = true),

                        style: {
                          width: "125px",
                        },
                      },
                      [
                        h("span", [
                          h("Icon", {
                            props: {
                              type: "",
                            },
                            style: {
                              marginLeft: "8px",
                              marginRight: "4px",
                            },
                          }),
                          h("span", data.title),
                          // h(
                          //     "span",
                          //     {
                          //         style: {
                          //             float: "right",
                          //             color: "grey",
                          //         },
                          //     },
                          //     this.form.Components
                          //         .num_vessels + "个"
                          // ),
                        ]),
                      ]
                    );
                  },
                },
                {
                  title: "附件2",
                  expand: true,
                  render: (h, { root, node, data }) => {
                    return h(
                      "div",
                      {
                        onClick: () =>
                          (this.output = true),

                        style: {
                          width: "125px",
                        },
                      },
                      [
                        h("span", [
                          h("Icon", {
                            props: {
                              type: "",
                            },
                            style: {
                              marginLeft: "8px",
                              marginRight: "4px",
                            },
                          }),
                          h("span", data.title),
                          // h(
                          //     "span",
                          //     {
                          //         style: {
                          //             float: "right",
                          //             color: "grey",
                          //         },
                          //     },
                          //     this.form.Components
                          //         .num_vessels + "个"
                          // ),
                        ]),
                      ]
                    );
                  },
                },
              ],
            },
            {
              render: (h, { root, node, data }) => {
                return h(
                  "span",
                  {
                    onClick: () =>
                      (this.AccidentVisible = true),
                  },
                  [
                    h("span", [
                      h(resolveComponent("Icon"), {
                        type: "ios-color-fill",
                        style: {
                          marginRight: "4px",
                        },
                      }),
                      h("span", data.title),
                    ]),
                  ]
                );
              },
              title: "全口径泄漏事件概率",
              expand: true,
            },
            {
              render: (h, { root, node, data }) => {
                return h(
                  "span",
                  {
                    onClick: () => (this.ignition = true),
                  },
                  [
                    h("span", [
                      h(resolveComponent("Icon"), {
                        type: "ios-color-wand",
                        style: {
                          marginRight: "4px",
                        },
                      }),
                      h("span", data.title),
                    ]),
                  ]
                );
              },
              title: "点火概率",
              expand: true,
            },
            {
              render: (h, { root, node, data }) => {
                return h(
                  "span",
                  {
                    onClick: () => (this.setting = true),
                  },
                  [
                    h("span", [
                      h(resolveComponent("Icon"), {
                        type: "ios-build",
                        style: {
                          marginRight: "4px",
                        },
                      }),
                      h("span", data.title),
                    ]),
                  ]
                );
              },
              title: "手动设置",
              expand: true,
            },
            // {
            //   render: (h, { root, node, data }) => {
            //     return h(
            //       "span",
            //       {
            //         onClick: () => (this.cloudVisible = true),
            //       },
            //       [
            //         h("span", [
            //           h(resolveComponent("Icon"), {
            //             type: "ios-cloud-outline",
            //             style: {
            //               marginRight: "4px",
            //             },
            //           }),
            //           h("span", data.title),
            //         ]),
            //       ]
            //     );
            //   },
            //   title: "风频与泄漏方向概率",
            //   expand: true,
            // },
          ],
        },
        {
          render: (h, { root, node, data }) => {
            return h("span", {}, [
              h("span", [
                h(resolveComponent("Icon"), {
                  type: "md-cube",
                  style: {
                    marginRight: "4px",
                  },
                }),
                h("span", data.title),
              ]),
            ]);
          },
          title: "物理参数",
          expand: true,
          children: [
            {
              title: "燃烧模型",
              expand: true,
              render: (h, { root, node, data }) => {
                return h("span", {}, [
                  h("span", [
                    h(resolveComponent("Icon"), {
                      type: "ios-flame",
                      style: {
                        marginRight: "4px",
                      },
                    }),
                    h("span", data.title),
                  ]),
                ]);
              },
              children: [
                {
                  render: (h, { root, node, data }) => {
                    return h(
                      "span",
                      {
                        onClick: () =>
                          (this.system_fire = true),
                      },
                      [h("span", [h("span", data.title)])]
                    );
                  },
                  title: "喷射火",
                  expand: true,
                },
                {
                  render: (h, { root, node, data }) => {
                    return h(
                      "span",
                      {
                        onClick: () =>
                          (this.system_boom = true),
                      },
                      [h("span", [h("span", data.title)])]
                    );
                  },
                  title: "爆炸",
                  expand: true,
                },
              ],
            },
            {
              render: (h, { root, node, data }) => {
                return h(
                  "span",
                  {
                    onClick: () => (this.pipe_param = true),
                  },
                  [
                    h("span", [
                      h(resolveComponent("Icon"), {
                        type: "md-git-commit",
                        style: {
                          marginRight: "4px",
                        },
                      }),
                      h("span", data.title),
                    ]),
                  ]
                );
              },
              title: "管道参数",
              expand: true,
            },
            {
              render: (h, { root, node, data }) => {
                return h(
                  "span",
                  {
                    onClick: () => (this.amb_param = true),
                  },
                  [
                    h("span", [
                      h(resolveComponent("Icon"), {
                        type: "md-map",
                        style: {
                          marginRight: "4px",
                        },
                      }),
                      h("span", data.title),
                    ]),
                  ]
                );
              },
              title: "环境参数",
              expand: true,
            },
          ],
        },
        {
          title: "地图信息",
          expand: true,
          render: (h, { root, node, data }) => {
            return h(
              "span",

              [
                h("span", [
                  h(resolveComponent("Icon"), {
                    type: "md-globe",
                    style: {
                      marginRight: "4px",
                    },
                  }),
                  h("span", data.title),
                ]),
              ]
            );
          },
          children: [
            {
              render: (h, { root, node, data }) => {
                return h(
                  "span",
                  {
                    onClick: () => {
                      this.isModal = true;
                      this.mapValue.value1 =
                        localStorage.getItem(
                          "realyWidth"
                        );
                      this.mapValue.value2 =
                        localStorage.getItem("xSize");
                      this.mapValue.value3 =
                        localStorage.getItem("delta");
                      this.mapValue.value4 =
                        localStorage.getItem(
                          "latticeSize"
                        );
                    },
                  },
                  [
                    h("span", [
                      h(resolveComponent("Icon"), {
                        type: "ios-options",
                        style: {
                          marginRight: "4px",
                        },
                      }),
                      h("span", data.title),
                    ]),
                  ]
                );
              },
              title: "地图尺寸",
            },
          ],
        },
        {
          render: (h, { root, node, data }) => {
            return h("span", {}, [
              h("span", [
                h(resolveComponent("Icon"), {
                  type: "md-trending-up",
                  style: {
                    marginRight: "4px",
                  },
                }),
                h(
                  "span",
                  {
                    onClick: () => (this.dzxVisible = true),
                  },
                  data.title
                ),
              ]),
            ]);
          },
          title: "等值线",
        },
      ],
      AccidentVisible: false,
      cloudVisible: false,
      cloudLoading: true,
      cloudRules: {
        initAngle: [
          { required: true, message: '初始角度不得为空', trigger: 'change' }
        ],
        frequencies: [
          { required: true, message: '请输入至少8个数值且总和等于1', trigger: 'change' },
          { validator: frequenciesValidator, trigger: 'blur' }
        ],
      }
    };
  },
  created() {
    this.modelFire = "dsb"
    this.form.System = {
      nozzle_model: "yuce",
      explosion_model: "tnt",
      exposure_time: 30,
      probit_thermal_id: "eise",
      bst_method_Flamspeed: 0,
      probit_explosion_id: "head",
      tnt_method_Factor: 0.03,
    };
    bus.off("personnel");
    bus.on("personnel", (list) => {
      this.lists = list;
      this.dataTree[2].children = [
        {
          render: (h, { root, node, data }) => {
            return h(
              "span",
              {
                onClick: () => {
                  this.isModal = true;
                  this.mapValue.value1 =
                    localStorage.getItem("realyWidth");
                  this.mapValue.value2 =
                    localStorage.getItem("xSize");
                  this.mapValue.value3 =
                    localStorage.getItem("delta");
                  this.mapValue.value4 =
                    localStorage.getItem("latticeSize");
                },
              },
              [
                h("span", [
                  h(resolveComponent("Icon"), {
                    type: "ios-options",
                    style: {
                      marginRight: "4px",
                    },
                  }),
                  h("span", data.title),
                ]),
              ]
            );
          },
          title: "地图尺寸",
        },
        ...this.lists.map((item, index) => {
          return {
            render: (h, { root, node, data }) => {
              return h(
                "span",
                {
                  onClick: () => {
                    this.isModal1 = true;
                    this.mapValue1.value1 = item.sum;
                    this.mapValue1.value2 = item.personNum
                    this.mapValue1.value3 = this.mapValue1.value2 / item.sum
                    this.id = item.id;
                  },
                },
                [
                  h("span", [
                    h(resolveComponent("Icon"), {
                      type: "ios-pie",
                      style: {
                        marginRight: "4px",
                      },
                    }),
                    h("span", data.title),
                  ]),
                ]
              );
            },
            title: `人口密度${index + 1}`,
          };
        }),
      ];
      if (this.isShotpoint) {
        this.dataTree[2].children.push({
          render: (h, { root, node, data }) => {
            return h(
              "span",
              {
                onClick: () => { },
              },
              [
                h("span", [
                  h("Icon", {
                    props: {
                      type: "ios-compass",
                    },
                    style: {
                      marginLeft: "8px",
                      marginRight: "4px",
                    },
                  }),
                  h("span", data.title),
                ]),
              ]
            );
          },
          title: "泄漏点",
        });
      }
    });
    bus.off("shotpoint");
    bus.on("shotpoint", () => {
      this.isShotpoint = true;
      this.dataTree[2].children = [
        {
          render: (h, { root, node, data }) => {
            return h(
              "span",
              {
                onClick: () => {
                  this.isModal = true;
                  this.mapValue.value1 =
                    localStorage.getItem("realyWidth");
                  this.mapValue.value2 =
                    localStorage.getItem("xSize");
                  this.mapValue.value3 =
                    localStorage.getItem("delta");
                  this.mapValue.value4 =
                    localStorage.getItem("latticeSize");
                },
              },
              [
                h("span", [
                  h(resolveComponent("Icon"), {
                    type: "ios-options",
                    style: {
                      marginRight: "4px",
                    },
                  }),
                  h("span", data.title),
                ]),
              ]
            );
          },
          title: "地图尺寸",
        },
        ...this.lists.map((item, index) => {
          return {
            render: (h, { root, node, data }) => {
              return h(
                "span",
                {
                  onClick: () => {
                    this.isModal1 = true;
                    this.mapValue1.value1 = item.sum;
                    this.id = item.id;
                  },
                },
                [
                  h("span", [
                    h(resolveComponent("Icon"), {
                      type: "ios-pie",
                      style: {
                        marginRight: "4px",
                      },
                    }),
                    h("span", data.title),
                  ]),
                ]
              );
            },
            title: `人口密度${index + 1}`,
          };
        }),
        {
          render: (h, { root, node, data }) => {
            return h(
              "span",
              {
                onClick: () => {
                  this.isModal2 = true;
                },
              },
              [
                h("span", [
                  h(resolveComponent("Icon"), {
                    type: "md-radio-button-on",
                    style: {
                      marginRight: "4px",
                    },
                  }),
                  h("span", data.title),
                ]),
              ]
            );
          },
          title: "泄漏点",
        },
      ];
    });
    // global.pllTempJSON = this.form;
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        // global.pllTempJSON = newVal;
      },
    },
  },
  computed: {
    freq() {
      const { num_vehicles, daily_fuelings, vehicle_days } =
        this.form.Vehicles;
      return num_vehicles * daily_fuelings * vehicle_days;
    },
  },
  methods: {
    submitCloud() {
      // console.log(done,1)
      // const arr=this.form.CompassRose.frequencies.split(',')
      // if(arr.length<8){
      //   this.$message.error('请输入至少8个数值且总和等于1')
      //   return
      // }
      // const sum = arr.reduce((pre,item)=>{
      //   pre+=Number(item)
      //   return pre
      // },0)
      // if(sum!==1){
      //   this.$message.error('请输入至少8个数值且总和等于1')
      //   return
      // }
      this.$refs.cloudFormRef.validate(valid => {
        if (valid) {
          this.cloudVisible = false
        } else {
          this.cloudLoading = false
          this.$nextTick(() => {
            this.cloudLoading = true
          })
        }
      })
    },
    beforeCloseCloud(done) {
      console.log(done, 2)
      return this.$refs.cloudFormRef.validate
    },
    chooseFireModel(value) {
      // global.pllTempJSON.Ignition.method=value
      this.form.Ignition.method = value
      if (value == 'dsb') {
        this.showFireList = false
      }
      if (value == 'dnv') {
        this.showFireList = true
      }
    },
    changeAngleTemp() {
      this.mapValue3.Angle = (this.mapValue3.AngleTemp * Math.PI) / 180;
    },
    submitPipe() {
      this.pipe_param = false;
    },
    changeExplosionmModel(val) {
      console.log(val, 11111);
      if (val === "bst") {
        this.form.System.bst_method_Flamspeed = 0.35;
        this.form.System.tnt_method_Factor = 0;
      } else if (val === "tnt") {
        this.form.System.bst_method_Flamspeed = 0;
        this.form.System.tnt_method_Factor = 0.03;
      } else {
        this.form.System.bst_method_Flamspeed = 0;
        this.form.System.tnt_method_Factor = 0;
      }
    },
    ok2() { },
    inputChange(e) {
      this.mapValue1.value3 = e.target.value / this.mapValue1.value1;
    },
    ok() {
      bus.emit("message", {
        trueWidth: this.mapValue.value2,
        latticeSize: this.mapValue.value4,
      });
    },
    ok1() {
      this.mapValue1.value3 = this.mapValue1.value2 / this.mapValue1.value1;
      bus.emit("density", {
        id: this.id,
        density: this.mapValue1.value3,
        personNum: this.mapValue1.value2
      });
    },
    add_ign_thresholds() {
      let temp = null;
      this.form.Ignition.ign_thresholds.push(temp);
    },
    submitSetModel() {
      this.setModule = false;
    },
    submitComponents() { },
    submitCapacityParam() {
      this.capacityParam = false;
    },
    submitSetting() { },
    submitIgnition() { },
    submitOutput() {
      this.output = false;
    },
    submitEnvironmentParam() {
      this.environmentParam = false;
    },
  },
};
</script>
<style scoped>
.normal-table {
  border-top: 1px solid black;
  border-left: 1px solid black;
}

.table-title {
  font-size: 16px;
  font-weight: 600;
  color: black;
  text-align: center;
  padding: 10px;
  background-color: rgb(222, 222, 222);
}

.table-left {
  font-size: 15px;
  font-weight: 500;
  color: black;
  text-align: center;
  height: 50px;
  padding: 12.5px;
}

.table-content {
  font-size: 15px;
  font-weight: 300;
  color: black;
  text-align: center;
  height: 50px;
  padding: 12.5px;
}

.table-border-bottom {
  border-bottom: 1px solid black;
}

.table-border-right {
  border-right: 1px solid black;
}

.table-title-left {
  font-size: 14px;
  font-weight: 500;
  color: black;
  text-align: center;
}

.modal-row {
  display: flex;
}

.modal-col {
  flex: 1;
  margin: 0 12px;
}
</style>