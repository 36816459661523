<template>
  <div>
    <el-radio-group v-model="pageType" @change="changePageType" class="page-type-group">
      <el-radio label="2" size="large">受限爆炸</el-radio>
      <el-radio label="1" size="large">非受限爆炸</el-radio>
    </el-radio-group>
    <!-- <el-switch v-model="pageType" size="large" active-text="受限爆炸" inactive-text="非受限爆炸" /> -->
    <Tree :data="dataTree"></Tree>
  </div>
  <Modal v-model="environmentParam" title="环境参数" @on-ok="submitEnvironmentParam" :mask-closable="false" draggable sticky
    width="700">
    <Form :model="form" :label-width="130" style="width: 100%">
      <FormItem label="环境温度">
        <Input v-model="form.amb_temperature" type="number">
        <template #append> K</template>
        </Input>
      </FormItem>
      <FormItem label="环境压力" type="number">
        <Input v-model="form.amb_pressure">
        <template #append> Pa</template>
        </Input>
      </FormItem>
      <FormItem label="受限空间体积" v-if="pageType === '2'" type="number">
        <Input v-model="form.confined_space_volume">
        <template #append>m <sup>3</sup></template>
        </Input>
      </FormItem>
    </Form>
  </Modal>
  <Modal v-model="setModule" title="模型选择" @on-ok="submitSetModel" :mask-closable="false" draggable sticky width="700"
    class-name="vertical-center-modal">
    <Form :model="form" :label-width="130" style="width: 100%">
      <FormItem label="计算模型">
        <Select v-model="form.method" @on-change="changeExplosionmModel">
          <Option value="bst">BST</Option>
          <Option value="tnt">TNT</Option>
          <Option value="bauwens">bauwens</Option>
        </Select>
      </FormItem>
      <FormItem label="虚拟喷嘴模型">
        <Select v-model="form.nozzleModel">
          <Option value="yuce">Yuceil/Otugen</Option>
          <Option value="birc">Birch</Option>
          <Option value="bir2">Birch2</Option>
          <Option value="ewan">Ewan/Moodie</Option>
          <Option value="molk">Molkov</Option>
        </Select>
      </FormItem>
      <FormItem label="流体相态">
        <Select v-model="form.fluid_phase">
          <Option value="none">气体</Option>
          <Option value="gas">饱和气体</Option>
          <Option value="liquid">饱和液体</Option>
        </Select>
      </FormItem>
      <FormItem label="马赫火焰速度" v-if="form.method === 'bst'" type="number">
        <!-- <Input v-model="form.bstFlameSpeed">
        </Input> -->
        <Select v-model="form.bstFlameSpeed">
          <!-- 0.7, 1.0, 1.4, 2.0, 3.0, 4.0, 5.2 -->
          <template v-if="pageType==='2'">
            <Option v-for="item in [0.7, 1.0, 1.4, 2.0, 3.0, 4.0, 5.2]" :value="item">{{item}}</Option>
          </template>
          <template v-else>
            <Option v-for="item in [0.2, 0.35, 0.7, 1.0, 1.4, 2.0, 3.0, 4.0, 5.2]" :value="item">{{item}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem label="TNT当量系数" v-if="form.method === 'tnt'" type="number">
        <Input v-model="form.tntFactor"> </Input>
      </FormItem>
    </Form>
  </Modal>
  <Modal v-model="output" title="输出设置" @on-ok="submitOutput" :mask-closable="false" draggable sticky width="700">
    <Form :model="form" :label-width="120" style="width: 100%">
      <Table border size="small" :columns="columnsX" :data="dataX">
        <template #x="{ row, index }">
          <Input v-model="row.x" @change="changeRow(row.x, 'x', index)" />
        </template>
        <template #y="{ row, index }">
          <Input v-model="row.y" @change="changeRow(row.y, 'y', index)" />
        </template>
        <template #z="{ row, index }">
          <Input v-model="row.z" @change="changeRow(row.z, 'z', index)" />
        </template>
      </Table>

      <FormItem label="压力关注轮廓" style="margin-top: 20px;">
        <Input v-model="contours" placeholder="请输入等值线数，以空格隔开">
        <template #append>Pa</template>
        </Input>
      </FormItem>
    </Form>
  </Modal>
  <Modal v-model="capacityParam" title="容器参数" @on-ok="submitCapacityParam" :mask-closable="false" draggable sticky
    width="700">
    <Form :model="form" label-position="right" :label-width="140" style="width: 100%">
      <FormItem label="泄漏直径">
        <Input :disabled="openLeakMass" v-model="form.orificeDiameter" type="number">
        <template #append>m</template></Input>
      </FormItem>
      <FormItem label="泄漏时间" v-if="pageType === '2'">
        <Input :disabled="openLeakMass" v-model="form.leak_duration" type="number">
        <template #append>s</template></Input>
      </FormItem>
      <FormItem label="泄放系数" type="number">
        <Input :disabled="openLeakMass" v-model="form.orificeDischargeCoefficient"> </Input>
      </FormItem>
      <FormItem label="泄漏角度" type="number">
        <Input :disabled="openLeakMass" v-model="form.orificeAngleTemp" @on-change="changeOrificeAngleTemp">
        <template #append>°</template>
        </Input>
      </FormItem>
      <FormItem label="流体压力" type="number">
        <Input :disabled="openLeakMass" v-model="form.fluid_pressure">
        <template #append>Pa</template></Input>
      </FormItem>
      <FormItem label="流体温度" v-if="form.fluid_phase === 'none'" type="number">
        <Input :disabled="openLeakMass" v-model="form.fluid_temperature"><template #append> K</template>
        </Input>
      </FormItem>
      <FormItem label="泄漏质量" class="xlzl" v-if="pageType === '2'">
        <Input :disabled="!openLeakMass" v-model="form.leak_mass" type="number">
        <template #append>kg</template></Input>
        <el-switch v-model="openLeakMass" />
      </FormItem>
    </Form>
  </Modal>
</template>
<script>
import global from "../assets/global.vue";
import { resolveComponent } from "vue";
export default {
  name: "overpressureTree",
  data() {
    return {
      openLeakMass: false,
      pageType: '1',
      contours: "3000 7000 11000 15000",
      columnsX: [
        {
          title: "X 坐标 (m)",
          key: "x",
          align: 'center',
          slot: 'x'
          // render: (h, params) => {
          //   return h(resolveComponent('Input'), {
          //     placeholder:'请输入坐标值',
          //     border:false,
          //     'model-value':params.row.x,
          //     'on-blur':(event) => {
          //         //获取编辑行的index和编辑字段名，对表格数据进行重新赋值
          //         this.dataX[params.index]['x'] =  parseFloat(params.row.x);
          //         console.log(this.dataX[params.index]['x'],1111)
          //       },
          //     on: {
          //       'on-change':(event) => {
          //         //获取编辑行的index和编辑字段名，对表格数据进行重新赋值
          //         this.dataX[params.index]['x'] =  parseFloat(params.row.x);
          //         console.log(this.dataX[params.index]['x'],1111)
          //       }
          //     },
          //   });
          // },
        },
        {
          title: "Y 坐标 (m)",
          key: "y",
          align: 'center',
          slot: 'y'
          // render: (h, params) => {
          //   return h(resolveComponent('Input'), {
          //     placeholder:'请输入坐标值',
          //     border:false,
          //     'model-value':params.row.y,
          //     on: {
          //       'on-change':(event) => {
          //         //获取编辑行的index和编辑字段名，对表格数据进行重新赋值
          //         this.dataX[params.index]['y'] =  parseFloat(event);

          //       }
          //     },
          //   });
          // },
        },
        {
          title: "Z 坐标 (m)",
          key: "z",
          align: 'center',
          slot: 'z'
          // render: (h, params) => {
          //   return h('div',[h(resolveComponent('Input'), {
          //     placeholder:'请输入坐标值',
          //     border:false,
          //     'model-value':params.row.z,
          //     //value: params.row.x,
          //     on: {
          //       'on-change':(event) => {
          //         //获取编辑行的index和编辑字段名，对表格数据进行重新赋值
          //         //this.dataX[params.index].z =  parseFloat(event);
          //         console.log(event)
          //       }
          //     },
          //   })

          //   ]);
          // },
        },
      ],
      dataX: [
        {
          x: 0.01,
          y: 1,
          z: 0.01,
        },
        {
          x: 0.5,
          y: 1,
          z: 0.5,
        },
        {
          x: 1,
          y: 1,
          z: 0.5,
        },
        {
          x: 2,
          y: 1,
          z: 1,
        },
        {
          x: 0,
          y: 0,
          z: 0,
        },
        {
          x: 0,
          y: 0,
          z: 0,
        },
        {
          x: 0,
          y: 0,
          z: 0,
        },
        {
          x: 0,
          y: 0,
          z: 0,
        },
        {
          x: 0,
          y: 0,
          z: 0,
        },
        {
          x: 0,
          y: 0,
          z: 0,
        },
      ],
      ////////
      setModule: false,
      output: false,
      environmentParam: false,
      capacityParam: false,
      ////////
      form: { ...JSON.parse(JSON.stringify(global.overpressureJSON)), orificeAngleTemp: 0 },
      dataTree: [
        {
          title: "模型选择",
          expand: true,
          render: (h, { root, node, data }) => {
            return h(
              "span",
              {
                onClick: () => this.setModule = true
              },
              [
                h("span", [
                  h(resolveComponent("Icon"), {
                    type: "logo-codepen",
                    style: {
                      marginLeft: "8px",
                      marginRight: "4px",
                    },
                  }),
                  h("span", data.title),
                ]),
              ]
            );
          },
        },
        {
          render: (h, { root, node, data }) => {
            return h(
              "span",
              {
                onClick: () => this.output = true,
              },
              [
                h("span", [
                  h(resolveComponent("Icon"), {
                    type: "ios-create",
                    style: {
                      marginLeft: "8px",
                      marginRight: "4px",
                    },
                  }),
                  h("span", data.title),
                ]),
              ]
            );
          },
          title: "输出设置",
          expand: true,
        },
        {
          render: (h, { root, node, data }) => {
            return h(
              "span",
              {
                onClick: () => this.environmentParam = true,
              },
              [
                h("span", [
                  h(resolveComponent("Icon"), {
                    type: "ios-compass",
                    style: {
                      marginLeft: "8px",
                      marginRight: "4px",
                    },
                  }),
                  h("span", data.title),
                ]),
              ]
            );
          },
          title: "环境参数",
          expand: true,
        },
        {
          render: (h, { root, node, data }) => {
            return h(
              "span",
              {
                onClick: () => this.capacityParam = true,
              },
              [
                h("span", [
                  h(resolveComponent("Icon"), {
                    type: "md-battery-dead",
                    style: {
                      marginLeft: "8px",
                      marginRight: "4px",
                    },
                  }),
                  h("span", data.title),
                ]),
              ]
            );
          },
          title: "容器参数",
          expand: true,
        },
      ],
    };
  },
  created() {
    global.overpressureTempJSON = this.form;
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        global.overpressureTempJSON = newVal;
      },
    },
  },
  methods: {
    // 切换类型
    changePageType(val) {
      this.openLeakMass = false
      if (val === '2') {
        this.form.method = 'bst'
        this.form.bstFlameSpeed = 5.2
        this.form.confined_space_volume = 1000
        this.form.leak_duration = 60
        this.form.leak_mass = 30
      } else {
        this.form.method = 'tnt'
      }
    },
    changeRow(val, key, index) {
      this.dataX[index][key] = Number(val)
    },
    changeOrificeAngleTemp() {
      this.form.orificeAngle = this.form.orificeAngleTemp * Math.PI / 180
    },
    changeExplosionmModel(val) {
      if (val === "bst") {
        this.form.bstFlameSpeed = 0.35;
        this.form.tntFactor = 0;
      } else if (val === "tnt") {
        this.form.bstFlameSpeed = 0;
        this.form.tntFactor = 0.03;
      } else {
        this.form.bstFlameSpeed = 0;
        this.form.tntFactor = 0;
      }
    },
    submitSetModel() {
      this.setModule = false;
    },
    submitCapacityParam() {

      this.capacityParam = false;
      // this.capacityParam = false;
    },
    submitOutput() {
      //处理contours
      let arr = this.contours;
      let arr_string = arr.split(" ");
      for (let i = 0; i < arr_string.length; i++) {
        arr_string[i] = parseFloat(arr_string[i]);
      }
      this.form.contours = arr_string;
      //处理XYZPods
      let x_arr = []
      let y_arr = []
      let z_arr = []
      for (let i = 0; i < 10; i++) {
        x_arr.push(this.dataX[i]['x'])
        y_arr.push(this.dataX[i]['y'])
        z_arr.push(this.dataX[i]['z'])
      }
      this.form.XPOS = x_arr
      this.form.YPOS = y_arr
      this.form.ZPOS = z_arr

      console.log(this.form.contours)
      console.log(this.form)
      global.overpressureTempJSON = this.form
      this.output = false;
    },
    submitEnvironmentParam() {
      this.environmentParam = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-type-group {
  padding-left: 22px;
}

.xlzl :deep(.ivu-form-item-content) {
  display: flex;
  align-items: center;
}
</style>