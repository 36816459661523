<template>
  <Layout style="height: 100%">
    <Sider class="sider-tree" hide-trigger>
      <accumulationTree ref="treeRef"></accumulationTree>
    </Sider>
    <Layout>
      <Header class="tool-bar">
        <Button type="success" @click="run()" shape="circle">
          <svg class="icon" aria-hidden="true" style="width: 20px">
            <use xlink:href="#icon-run-solid"></use>
          </svg>
          运行
        </Button>



        <FooterMsg></FooterMsg>
      </Header>
      <Content class="content">
        <Tabs v-if="runed" value="name1">
          <TabPane label="气层分布" name="name0">
            <!-- <Image
                            :width="imgWidth"
                            :height="imgHeight"
                            style="margin-left: 10%;"
                            :src="img_layer_plot_filepath"
                            class="img"
                        >
                        </Image> -->
            <el-image style="width:90%;height:90%" :src="img_layer_plot_filepath" :zoom-rate="1.2" :max-scale="7"
              :preview-teleported="true" :min-scale="0.2" :preview-src-list="[img_layer_plot_filepath]" :initial-index="1"
              fit="contain" />
          </TabPane>
          <TabPane label="质量流率" name="name1">
            <!-- <Image :width="imgWidth" :height="imgHeight" style="margin-left: 10%;" :src="img_mass_flow_plot_filepath"
              class="img">
            </Image> -->
            <el-image style="width:90%;height:90%" :src="img_mass_flow_plot_filepath" :zoom-rate="1.2" :max-scale="7"
              :preview-teleported="true" :min-scale="0.2" :preview-src-list="[img_mass_flow_plot_filepath]"
              :initial-index="1" fit="contain" />
          </TabPane>
          <TabPane label="可燃物质质量" name="name2">
            <!-- <Image :width="imgWidth" :height="imgHeight" style="margin-left: 10%;" :src="img_mass_plot_filepath"
              class="img">
            </Image> -->
            <el-image style="width:90%;height:90%" :src="img_mass_plot_filepath" :zoom-rate="1.2" :max-scale="7"
              :preview-teleported="true" :min-scale="0.2" :preview-src-list="[img_mass_plot_filepath]" :initial-index="1"
              fit="contain" />
          </TabPane>
          <TabPane label="压力值" name="name3">
            <!-- <Image :width="imgWidth" :height="imgHeight" style="margin-left: 10%;" :src="img_pres_plot_filepath"
              class="img">
            </Image> -->
            <el-image style="width:90%;height:90%" :src="img_pres_plot_filepath" :zoom-rate="1.2" :max-scale="7"
              :preview-teleported="true" :min-scale="0.2" :preview-src-list="[img_pres_plot_filepath]" :initial-index="1"
              fit="contain" />
          </TabPane>
          <TabPane label="烟羽轨迹" name="name4">
            <!-- <Image :width="imgWidth" :height="imgHeight" style="margin-left: 10%;" :src="img_trajectory_plot_filepath"
              class="img">
            </Image> -->
            <el-image style="width:90%;height:90%" :src="img_trajectory_plot_filepath" :zoom-rate="1.2" :max-scale="7"
              :preview-teleported="true" :min-scale="0.2" :preview-src-list="[img_trajectory_plot_filepath]"
              :initial-index="1" fit="contain" />
          </TabPane>
          <TabPane label="相关数据" name="name5">
            <div style="width:80%;margin:auto">
              <div>峰值超压:{{ response.overpressure }} Pa</div>
              <div>峰值超压时刻:{{ response.time_of_overp }} s</div>
              <Table align="center" height="calc(100% - 80px)" border :columns="columns" :data="tableData"></Table>
            </div>

          </TabPane>
        </Tabs>
        <Image v-else width="60%" :src="require('@/assets/default-bg.png')"
          style="display:block;left: 50%;top: 50%;transform: translate(-50%, -50%);" />
      </Content>
    </Layout>
  </Layout>
  <Spin size="large" fix :show="spinShow"></Spin>
</template>
<script>
import FooterMsg from "../components/footer.vue";
import accumulationTree from "../components/accumulationComponent.vue";
import global from "../assets/global.vue";
import http from "../assets/axiosInstance";
import defaultLogo from "@/assets/default-bg.png"
export default {
  data() {
    return {
      runed: false,//是否运行
      spinShow: false, //加载
      response: {},
      imgWidth: "80%",
      imgHeight: "auto",
      img_layer_plot_filepath:
        defaultLogo,
      img_mass_flow_plot_filepath:
        defaultLogo,
      img_mass_plot_filepath:
        defaultLogo,
      img_pres_plot_filepath:
        defaultLogo,
      img_trajectory_plot_filepath:
        defaultLogo,
      columns: [
        {
          title: '时刻 (s)',
          key: 'time'
        },
        {
          title: '总压力 (Pa)',
          key: 'pressures_per_time'
        },
        {
          title: '气层厚度 (m)',
          key: 'depths'
        },
        {
          title: '可燃物浓度 (%)',
          key: 'concentrations'
        },
        {
          title: '质量流率 (kg/s)',
          key: 'mass_flow_rates'
        },
      ],
      tableData: []
    };
  },
  components: { accumulationTree, FooterMsg },
  created() {
    // http({
    //         url: "/checkToken",
    //         method: "post",
    //         data: {
    //             token:localStorage.getItem('token'),
    //             username:localStorage.getItem('name')
    //         },
    //     })
    //         .then((res) => {
    //             if(!res.data.available){
    //                 localStorage.clear()
    //                 this.$router.push('/login')
    //             }
    //         })
    //         .catch(function (error) {});
  },
  methods: {
    dataCheck(obj) {
      let keyNameArr = Object.keys(obj)
      for (let i = 0; i < keyNameArr.length; i++) {
        //console.log(typeof obj[keyNameArr[i]])
        if (
          keyNameArr[i] == "fluid_phase" ||
          keyNameArr[i] == "nozzleModel" ||
          keyNameArr[i] == "times" ||
          keyNameArr[i] == "point_Pressure" ||
          keyNameArr[i] == "point_Time" ||
          keyNameArr[i] == "line_Pressure"
        ) {
          console.log("累积效应无需修正数据", keyNameArr[i], typeof obj[keyNameArr[i]])
          continue
        }
        obj[keyNameArr[i]] = parseFloat(obj[keyNameArr[i]])
        console.log("累积效应数据修正", keyNameArr[i], typeof obj[keyNameArr[i]])
      }
    },
    async run() {
      const { fluid_phase, fluid_pressure } = this.$refs.treeRef.form
      if (['gas', 'liquid'].includes(fluid_phase) && (Number(fluid_pressure) > 1296400 || Number(fluid_pressure) < 101325)) {
        this.$message({
          message: '流体压力输入值应在101325Pa——1296400Pa之间',
          showClose: true,
          type: 'error',
          duration: 0
        })
        this.$nextTick(() => {
          this.capacityParam = true
        })
        return false
      }
      this.dataCheck(global.accumulationTempJSON)
      this.spinShow = true;
      //console.log("运行参数pll", global.pllTempJSON);
      const params = { ...global.accumulationTempJSON }
      delete params.orificeAngleTemp
      http({
        url: "/c_sat/ppc/accumulation",
        method: "post",
        data: params,
      })
        .then((res) => {
          if (res.data) {
            this.runed = true
            this.$Notice.success({
              title: "累积效应计算成功！",
            });
            // this.imgWidth = "500px";
            this.response = res.data;
            this.tableData = res.data.depths.reduce((pre, item, i) => {
              const { pressures_per_time, concentrations, mass_flow_rates } = res.data
              pre.push({
                pressures_per_time: pressures_per_time[i],
                concentrations: concentrations[i],
                mass_flow_rates: mass_flow_rates[i],
                depths: item,
                time: params.times[i]
              })
              return pre
            }, [])
            this.img_mass_flow_plot_filepath =
              "/api" +
              res.data.mass_flow_plot_filepath;
            this.img_mass_plot_filepath =
              "/api" +
              res.data.mass_plot_filepath;
            this.img_pres_plot_filepath =
              "/api" +
              res.data.pres_plot_filepath;
            this.img_layer_plot_filepath =
              "/api" +
              res.data.layer_plot_filepath;
            this.img_trajectory_plot_filepath =
              "/api" +
              res.data.trajectory_plot_filepath;
            console.log(res);
            this.spinShow = false;
          }
          if (!res.data) {
            this.$Notice.error({
              title: "错误",
              desc: "计算失败，请检查后端是否返回数据",
            });
            this.spinShow = false;
          }
        })
        .catch(function (error) {
          alert(error);
          this.spinShow = false;
        });
    },

    save() {
      console.log("保存参数pll", global.pllTempJSON);
    },
  },
};
</script>
<style scoped>
.sider-tree {
  background: #fff;
  border: 1px solid #dcdee2;
  border-radius: 5px;
  margin: 15px 0 15px 15px;
  overflow-y: auto;
}

.tool-bar {
  background-color: #fff;
  margin: 15px;
  border-radius: 5px;
  border: 1px solid #dcdee2;
}

.content {
  margin: 0 15px 15px 15px;
  border: 1px solid #dcdee2;
  border-radius: 5px;
  background-color: #fff;
  overflow-y: auto;
}

/* .img {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
} */
</style>
