<template>
  <el-dialog v-model="visible" title="泄漏点" width="1000px" @closed="closed">
    <el-form :rules="rules" ref="formRef" label-width="80px" :model="formData">
      <el-row>
        <el-col :span="12">
          <el-form-item prop="num_compressors" label="压缩机">
            <el-input v-model="formData.num_compressors" placeholder="请输入压缩机数量">
              <template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="num_valves" label="阀门">
            <el-input v-model="formData.num_valves" placeholder="请输入阀门数量">
              <template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item prop="num_vessels" label="储罐">
            <el-input v-model="formData.num_vessels" placeholder="请输入储罐数量">
              <template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="num_instruments" label="设备">
            <el-input v-model="formData.num_instruments" placeholder="请输入设备数量">
              <template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item prop="num_joints" label="结合处">
            <el-input v-model="formData.num_joints" placeholder="请输入结合处数量">
              <template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="num_hoses" label="软管">
            <el-input v-model="formData.num_hoses" placeholder="请输入软管数量">
              <template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item prop="pipe_length" label="管道长度">
            <el-input v-model="formData.pipe_length" placeholder="请输入管道长度">
              <template #append>米</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="num_filters" label="过滤器">
            <el-input v-model="formData.num_filters" placeholder="请输入过滤器数量">
              <template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item prop="num_flanges" label="法兰">
            <el-input v-model="formData.num_flanges" placeholder="请输入法兰数量">
              <template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="num_exchangers" label="热交换器">
            <el-input v-model="formData.num_exchangers" placeholder="请输入热交换器数量">
              <template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item prop="num_vaporizers" label="冷凝器">
            <el-input v-model="formData.num_vaporizers" placeholder="请输入冷凝器数量">
              <template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="num_arms" label="装卸器">
            <el-input v-model="formData.num_arms" placeholder="请输入装卸器数量">
              <template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item prop="num_extra_comp1" label="附件1">
            <el-input v-model="formData.num_extra_comp1" placeholder="请输入附件1数量">
              <template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="num_extra_comp2" label="附件2">
            <el-input v-model="formData.num_extra_comp2" placeholder="请输入附件2数量">
              <template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item prop="YAvrg" label="泄漏高度">
            <el-input v-model="formData.YAvrg" placeholder="请输入泄漏高度">
              <template #append>米</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="AngleTemp" label="泄漏角度">
            <el-input v-model="formData.AngleTemp" placeholder="请输入泄漏角度">
              <template #append>°</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="submit">
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      formData: {
        pipe_length: 20,
        num_compressors: 0,
        num_vessels: 0,
        num_valves: 5,
        num_instruments: 3,
        num_joints: 35,
        num_hoses: 1,
        num_filters: 0,
        num_flanges: 0,
        num_exchangers: 0,
        num_vaporizers: 0,
        num_arms: 0,
        num_extra_comp1: 0,
        num_extra_comp2: 0,
        YAvrg: 1,
        Angle: 0,
        AngleTemp: 0,
      },
      rules: {
        pipe_length: [{ required: true, message: '管道长度不得为空' }],
        num_compressors: [{ required: true, message: '压缩机数量不得为空' }],
        num_valves: [{ required: true, message: '阀门数量不得为空' }],
        num_vessels: [{ required: true, message: '储罐数量不得为空' }],
        num_instruments: [{ required: true, message: '设备数量不得为空' }],
        num_joints: [{ required: true, message: '结合处数量不得为空' }],
        num_hoses: [{ required: true, message: '软管数量不得为空' }],
        num_filters: [{ required: true, message: '过滤器数量不得为空' }],
        num_flanges: [{ required: true, message: '法兰数量不得为空' }],
        num_exchangers: [{ required: true, message: '热交换器数量不得为空' }],
        num_vaporizers: [{ required: true, message: '冷凝器数量不得为空' }],
        num_arms: [{ required: true, message: '装卸器数量不得为空' }],
        num_extra_comp1: [{ required: true, message: '附件1数量不得为空' }],
        num_extra_comp2: [{ required: true, message: '附件2数量不得为空' }],
        YAvrg: [{ required: true, message: '泄漏高度不得为空' }],
        AngleTemp: [{ required: true, message: '泄漏角度不得为空' }],
      },
    }
  },
  methods: {
    init(data) {
      this.visible = true
      this.$nextTick(() => {
        Object.assign(this.formData, data)
      })
    },
    closed() {
      this.$refs.formRef?.resetFields()
    },
    submit() {
      this.$refs.formRef.validate((vaild) => {
        if (vaild) {
          this.formData.Angle = (this.formData.AngleTemp * Math.PI) / 180;
          this.$emit('submit', { ...this.formData })
          this.visible = false
        }
      })
    }
  }
}
</script>
