<template>
  <Layout style="height: 100%">
    <Sider class="sider-tree" hide-trigger>
      <jetflameTree ref="treeRef"></jetflameTree>
    </Sider>
    <Layout>
      <Header class="tool-bar"><Button type="success" @click="run()" shape="circle"><svg class="icon" aria-hidden="true"
            style="width: 20px">
            <use xlink:href="#icon-run-solid"></use>
          </svg> 运行</Button>
        <template v-if="gis.enable">
          <ImgUpload ref="imgUploadRef" @uploadSuccess="uploadSuccess" />
          <Button style="margin-left:16px" @click="chosePoint = true; $Message.success('可标记泄漏位置');" type="warning"
            shape="circle">
            <svg class="icon" aria-hidden="true" style="width: 20px">
              <use xlink:href="#icon-daoru"></use>
            </svg>
            标记位置
          </Button>
        </template>
        <Button type="success" @click="changeEnalbe()" shape="circle" style="margin-top:20px;float:right">{{ gis.enable ?
          '关闭' : '启用' }}GIS</Button>
        <FooterMsg></FooterMsg>
      </Header>
      <Content class="content" style="transform: translateX(0)">
        <!-- <Tabs value="name1" v-if="runed">
                    <TabPane label="喷射火三视图" name="name0">
                        <Image
                            :width="imgWidth"
                            :height="imgHeight"
                            :src="img_flux_plot_filepath"
                            class="img"
                        >
                        </Image>
                    </TabPane>
                    <TabPane label="火焰温度" name="name5">
                        <Image
                            :width="imgWidth"
                            :height="imgHeight"
                            :src="img_temp_plot_filepath"
                            class="img"
                        >
                        </Image>
                    </TabPane>
                    <TabPane label="热辐射数据" name="name1">
                      <div class="single-pool-table">
                          <div>质量流率：{{resultData.mass_flow_rate}} kg/s</div>
                          <div>总热辐射功率：{{resultData.srad}} KW</div>
                          <Table
                            border
                            size="small"
                            :columns="columnsSinglePool"
                            :data="tableData"
                        ></Table>
                      </div>
                    </TabPane>
                    <TabPane label="GIS" v-if="gis.enable" name="name3">
                        <Image
                            :width="imgWidth"
                            :height="imgHeight"
                            :src="gisImagePath"
                            class="img"
                        >
                        </Image>
                    </TabPane>
                </Tabs> -->
        <Modal :transfer="false" :footer-hide="true" :fullscreen="true" v-model="viewVisible" title="运行结果">
          <Tabs value="name1" style="height: 100%;">
            <TabPane label="喷射火三视图" name="name0">
              <!-- <Image :width="imgWidth" :height="imgHeight" :src="img_flux_plot_filepath" class="img">
              </Image> -->
              <el-image style="width:90%;height:90%" :src="img_flux_plot_filepath" :zoom-rate="1.2" :max-scale="7"
                :preview-teleported="true" :min-scale="0.2" :preview-src-list="[img_flux_plot_filepath]"
                :initial-index="1" fit="contain" />
            </TabPane>
            <TabPane label="火焰温度" name="name5">
              <!-- <Image :width="imgWidth" :height="imgHeight" :src="img_temp_plot_filepath" class="img">
              </Image> -->
              <el-image style="width:90%;height:90%" :src="img_temp_plot_filepath" :zoom-rate="1.2" :max-scale="7"
                :preview-teleported="true" :min-scale="0.2" :preview-src-list="[img_temp_plot_filepath]"
                :initial-index="1" fit="contain" />
            </TabPane>
            <TabPane label="热辐射数据" name="name1">
              <div class="single-pool-table">
                <div>质量流率：{{ resultData.mass_flow_rate }} kg/s</div>
                <div>总热辐射功率：{{ resultData.srad }} KW</div>
                <Table border size="small" :columns="columnsSinglePool" :data="tableData"></Table>
              </div>
            </TabPane>
            <TabPane label="GIS" v-if="gis.enable" name="name3">
              <el-image style="width:90%;height:90%" :src="gisImagePath" :zoom-rate="1.2" :max-scale="7"
                :preview-teleported="true" :min-scale="0.2" :preview-src-list="[gisImagePath]" :initial-index="1"
                fit="contain" />
              <!-- <Image :width="imgWidth"  :height="imgHeight" :src="gisImagePath" preview-teleported="true" class="img">
              </Image> -->
            </TabPane>
          </Tabs>
        </Modal>
        <template v-if="!viewVisible">
          <div v-if="gis.imageurl" ref="gisBox" class="gis-box" @click="gisClick">
            <img ref="gisImgRef" :src="gis.imageurl" class="gis-img">
            <div :style="pointStyle" class="gis-po"></div>
          </div>
          <!-- <Image v-else width="100%"  src="@/assets/default-bg.png" style="display:block;left: 50%;top: 50%;transform: translate(-50%, -50%);"/> -->
          <Image v-else width="60%" :src="require('@/assets/default-bg.png')"
            style="display:block;left: 50%;top: 50%;transform: translate(-50%, -50%);" />
        </template>
      </Content>
    </Layout>
  </Layout>
  <Spin size="large" fix :show="spinShow"> </Spin>
</template>
<script>
import ImgUpload from "../components/imgUpload.vue"
import FooterMsg from "../components/footer.vue";
import jetflameTree from "../components/jetflameComponent.vue";
import global from "../assets/global.vue";
import http from "../assets/axiosInstance";
export default {
  data() {
    return {
      enable: false,//是否启用gis
      runed: false,
      spinShow: false,
      response: null,
      imgHeight: "auto",
      imgWidth: "100%",
      img_flux_plot_filepath: require('@/assets/default-bg.png'),
      img_temp_plot_filepath: require('@/assets/default-bg.png'),
      gisImagePath: '',
      columnsSinglePool: [
        {
          title: "X (m)",
          key: "x",
        },
        {
          title: "Y (m)",
          key: "y",
        },
        {
          title: "Z (m)",
          key: "z",
        },
        {
          title: "热辐射[kW/m²]",
          key: "flux_data",
        },
      ],
      tableData: [],
      resultData: {},
      gis: {
        enable: false,
        imageurl: '',
        meterPerPixel: 0,
        centerX: 0,
        centerY: 0,
        rotate: 0,
      },
      chosePoint: false,
      pointStyle: {
        left: 0,
        top: 0,
      },
      viewVisible: false
    };
  },
  created() {
    // http({
    //         url: "/checkToken",
    //         method: "post",
    //         data: {
    //             token:localStorage.getItem('token'),
    //             username:localStorage.getItem('name')
    //         },
    //     })
    //         .then((res) => {
    //             if(!res.data.available){
    //                 localStorage.clear()
    //                 this.$router.push('/login')
    //             }
    //         })
    //         .catch(function (error) {});
  },
  components: { jetflameTree, FooterMsg, ImgUpload },
  methods: {
    gisClick(e) {
      if (this.chosePoint) {
        const { layerX, layerY } = e
        const y = (layerY / this.$refs.gisBox.offsetHeight)
        const x = (layerX / this.$refs.gisBox.offsetWidth)
        this.pointStyle = {
          left: x * 100 + '%',
          top: y * 100 + '100%'
        }
        const { naturalWidth, naturalHeight } = this.$refs.gisImgRef
        this.gis.centerX = naturalWidth * x
        this.gis.centerY = naturalHeight * y
        this.chosePoint = false
      }
    },
    changeEnalbe() {
      this.gis.enable = !this.gis.enable
      this.gis.imageurl = '',
        this.gis.meterPerPixel = 0
      this.gis.centerX = 0
      this.gis.centerY = 0
      this.gis.rotate = 0
      if (this.gis.enable) {
        this.img_flux_plot_filepath = require('@/assets/default-bg.png')
        this.img_temp_plot_filepath = require('@/assets/default-bg.png')
        this.runed = false
        this.$nextTick(() => {
          this.$refs.imgUploadRef.init()
        })
        this.viewVisible = false
      }
    },
    uploadSuccess(data) {
      this.viewVisible = false
      const { src: imageurl, rotate, meterPerPixel } = data
      Object.assign(this.gis, { imageurl, rotate, meterPerPixel })
    },
    dataCheck(obj) {
      let keyNameArr = Object.keys(obj);

      for (let i = 0; i < keyNameArr.length; i++) {
        if (
          keyNameArr[i] == "fluid_phase" ||
          keyNameArr[i] == "nozzleModel" ||
          keyNameArr[i] == "rad_src_key" ||
          keyNameArr[i] == "XPOS" ||
          keyNameArr[i] == "YPOS" ||
          keyNameArr[i] == "ZPOS" ||
          keyNameArr[i] == "contours"
        ) {
          console.log("喷射火无需修正数据", keyNameArr[i], typeof obj[keyNameArr[i]])
          continue
        }
        obj[keyNameArr[i]] = parseFloat(obj[keyNameArr[i]])
        console.log("喷射火数据修正", keyNameArr[i], typeof obj[keyNameArr[i]]);
      }
    },
    async run() {
      if (this.gis.enable && !this.gis.imageurl) {
        this.$message({
          message: '请先上传gis地图',
          showClose: true,
          type: 'error',
          duration: 0
        })
        return false
      }
      const { fluid_phase, fluid_pressure } = this.$refs.treeRef.form
      if (['gas', 'liquid'].includes(fluid_phase) && (Number(fluid_pressure) > 1296400 || Number(fluid_pressure) < 101325)) {
        this.$message({
          message: '流体压力输入值应在101325Pa——1296400Pa之间',
          showClose: true,
          type: 'error',
          duration: 0
        })
        this.$nextTick(() => {
          this.capacityParam = true
        })
        return false
      }
      this.spinShow = true;
      this.dataCheck(global.jetflameTempJSON,)
      const species = this.$refs.treeRef.submitGoods()
      if (!species) return
      http({
        url: "/c_sat/ppc/jetflame",
        method: "post",
        data: { ...global.jetflameTempJSON, gis: this.gis, species },
      })
        .then((res) => {
          if (res.data) {
            this.runed = true
            this.tableData = []
            this.$Notice.success({
              title: "喷射火热辐射计算成功！",
            });
            console.log(res)
            this.response = res;
            this.resultData = res.data
            const tempList = this.$refs.treeRef.dataX
            this.tableData = res.data.flux_data.reduce((pre, item, index) => {
              const { x, y, z } = tempList[index]
              console.log(x, y, z, pre, 11111)
              pre.push({ flux_data: item, x, y, z })
              return pre
            }, [])
            // this.imgHeight='100%'
            // this.imgWidth = "80%";
            this.img_flux_plot_filepath =
              "/api" +
              res.data.flux_plot_filepath;
            this.img_temp_plot_filepath =
              "/api" +
              res.data.temp_plot_filepath;
            this.gisImagePath =
              "/api" +
              res.data.gis_plot_filepath;
            this.viewVisible = true
          }
          if (!res.data) {
            this.$Notice.error({
              title: "错误",
              desc: "计算失败，请检查后端是否返回数据",
            });
            console.log("计算失败，请检查后端是否返回数据")
          }
          this.spinShow = false;
        })
        .catch(function (error) {
          alert(error);
          this.spinShow = false;
        });
    },

    save() {
      console.log("保存参数pll", global.pllTempJSON);
    },
  },
};
</script>
<style scoped>
.sider-tree {
  background: #fff;
  border: 1px solid #dcdee2;
  border-radius: 5px;
  margin: 15px 0 15px 15px;
  overflow-y: auto;
}

.tool-bar {
  background-color: #fff;
  margin: 15px;
  border-radius: 5px;
  border: 1px solid #dcdee2;
}

.content {
  margin: 0 15px 15px 15px;
  border: 1px solid #dcdee2;
  border-radius: 5px;
  background-color: #fff;
  overflow-y: auto;
}

/* .img {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
} */
.single-pool-table {
  padding: 20px;
  text-align: center;
  width: 100%;
}

.gis-img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.gis-box {
  width: fit-content;
  margin: auto;
  position: relative
}

.gis-po {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #000;
  border: 2px solid #fff;
  padding: 3px;

  &::before {
    content: '';
    display: block;
    background: #fff;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
</style>
