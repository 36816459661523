<template>
  <Layout style="height: 100%">
    <Sider class="sider-tree" hide-trigger>
      <plumeTree ref="treeRef"></plumeTree>
    </Sider>
    <Layout>
      <Header class="tool-bar">
        <Button type="success" @click="run()" shape="circle">
          <svg class="icon" aria-hidden="true" style="width: 20px">
            <use xlink:href="#icon-run-solid"></use>
          </svg>
          运行
        </Button>
        <template v-if="gis.enable">
          <ImgUpload ref="imgUploadRef" @uploadSuccess="uploadSuccess" />
          <Button style="margin-left:16px" @click="chosePoint = true; $Message.success('可标记泄漏位置');" type="warning"
            shape="circle">
            <svg class="icon" aria-hidden="true" style="width: 20px">
              <use xlink:href="#icon-daoru"></use>
            </svg>
            标记位置
          </Button>
        </template>
        <Button type="success" @click="changeEnalbe" shape="circle" style="margin-top:20px;float:right">{{ gis.enable ?
          '关闭' : '启用' }}GIS</Button>
        <FooterMsg></FooterMsg>
      </Header>
      <Content class="content" style="transform: translateX(0)">
        <template v-if="!viewVisible">
          <div v-if="gis.imageurl" ref="gisBox" class="gis-box" @click="gisClick">
            <img ref="gisImgRef" :src="gis.imageurl" class="gis-img">
            <div :style="pointStyle" class="gis-po"></div>
          </div>
          <Image v-else width="60%" style="display:block;left: 50%;top: 50%;transform: translate(-50%, -50%);"
            :src="require('@/assets/default-bg.png')" class="img" />
        </template>
        <Modal :transfer="false" :footer-hide="true" :fullscreen="true" v-model="viewVisible" title="运行结果">
          <Tabs value="name1" style="height: 100%">
            <TabPane label="浓度视图" name="name0">
              <!-- <Image
                        width="100%"
                        height="auto"
                        :src="imgSRC"
                        class="img"
                    >
                    </Image> -->
              <el-image style="width:90%;height:90%" :src="imgSRC" :zoom-rate="1.2" :max-scale="7"
                :preview-teleported="true" :min-scale="0.2" :preview-src-list="[imgSRC]" :initial-index="1"
                fit="contain" />
            </TabPane>
            <TabPane label="浓度数据" name="name1">
              <div class="data" v-if="mole_fracs.length">
                <Table align="center" border :columns="mole_fracs_col" :data="mole_fracs"></Table>

              </div>
              <Image width="60%" v-else :src="require('@/assets/default-bg.png')" style="display:block;margin:auto" />
            </TabPane>
            <TabPane label="GIS" v-if="gis.enable" name="name3">
              <el-image style="width:90%;height:90%" :src="gisImagePath" :zoom-rate="1.2" :max-scale="7"
                :preview-teleported="true" :min-scale="0.2" :preview-src-list="[gisImagePath]" :initial-index="1"
                fit="contain" />
            </TabPane>
          </Tabs>
        </Modal>
      </Content>
    </Layout>
  </Layout>
  <Spin size="large" fix :show="spinShow"></Spin>
</template>
<script>
import FooterMsg from "../components/footer.vue";
import plumeTree from "../components/plumeComponent.vue";
import global from "../assets/global.vue";
import http from "../assets/axiosInstance";
import ImgUpload from "../components/imgUpload.vue"
export default {
  data() {
    return {
      response: null,
      imgSRC: '',
      imgHeight: "auto",
      imgWidth: "60%",
      spinShow: false,
      viewVisible: false,
      mole_fracs: [],
      mole_fracs_col: [
        {
          title: "x (m)",
          key: "x",
        },
        {
          title: "y (m)",
          key: "y",
        },
        {
          title: "摩尔浓度",
          key: "mole_fracs",
        },
      ],
      gis: {
        enable: false,
        imageurl: '',
        meterPerPixel: 0,
        centerX: 0,
        centerY: 0,
        rotation: 0,
      },
      chosePoint: false,
      runed: false,
      pointStyle: {
        left: 0,
        top: 0,
      },
      gisImagePath: '',
    };
  },
  components: { plumeTree, FooterMsg, ImgUpload },
  methods: {
    gisClick(e) {
      if (this.chosePoint) {
        const { layerX, layerY } = e
        const y = (layerY / this.$refs.gisBox.offsetHeight)
        const x = (layerX / this.$refs.gisBox.offsetWidth)
        this.pointStyle = {
          left: x * 100 + '%',
          top: y * 100 + '100%'
        }
        const { naturalWidth, naturalHeight } = this.$refs.gisImgRef
        this.gis.centerX = naturalWidth * x
        this.gis.centerY = naturalHeight * y
        this.chosePoint = false
      }
    },
    changeEnalbe() {
      this.gis.enable = !this.gis.enable
      this.gis.imageurl = '',
        this.gis.meterPerPixel = 0
      this.gis.centerX = 0
      this.gis.centerY = 0
      this.gis.rotation = 0
      if (this.gis.enable) {
        this.viewVisible = false
        this.runed = false
        this.$nextTick(() => {
          this.$refs.imgUploadRef.init()
        })
      }
    },
    uploadSuccess(data) {
      this.viewVisible = false
      const { src: imageurl, rotate: rotation, meterPerPixel } = data
      Object.assign(this.gis, { imageurl, rotation, meterPerPixel })
    },
    dataCheck(obj) {
      let keyNameArr = Object.keys(obj)
      for (let i = 0; i < keyNameArr.length; i++) {
        //console.log(typeof obj[keyNameArr[i]])
        if (
          keyNameArr[i] == "fluidPhase" ||
          keyNameArr[i] == "nozzleModel" ||
          keyNameArr[i] == "is_coordinate_adaptive"
        ) {
          console.log("羽流分布无需修正数据", keyNameArr[i], typeof obj[keyNameArr[i]])
          continue
        }
        obj[keyNameArr[i]] = parseFloat(obj[keyNameArr[i]])
        console.log("羽流分布数据修正", keyNameArr[i], typeof obj[keyNameArr[i]])
      }
    },
    async run() {
      const { fluidPhase, fluid_pressure, points, is_coordinate_adaptive } = this.$refs.treeRef.form
      if (['gas', 'liquid'].includes(fluidPhase) && (Number(fluid_pressure) > 1296400 || Number(fluid_pressure) < 101325)) {
        this.$message({
          message: '流体压力输入值应在101325Pa——1296400Pa之间',
          showClose: true,
          type: 'error',
          duration: 0
        })

        this.$nextTick(() => {
          this.capacityParam = true
        })
        return false
      }
      this.dataCheck(global.plumeTempJSON)
      this.spinShow = true;
      const params = global.plumeTempJSON
      params.points = points
      const points1 = points.reduce((pre, item) => {
        const { x, y } = item
        pre.push([x, y])
        return pre
      }, [])
      http({
        url: "/c_sat/ppc/plume_dispersion",
        method: "post",
        data: { ...params, points: points1, gis: this.gis, is_coordinate_adaptive }
      })
        .then((res) => {
          if (res.data) {
            this.$Notice.success({
              title: "羽流分布计算成功！",
            });
            this.response = res;
            this.imgHeight = "100%";
            this.imgWidth = "100%";
            this.imgSRC =
              "/api" + res.data.plot;
            this.gisImagePath =
              "/api" +
              res.data.plot_gis;
            this.mole_fracs = res.data.mole_fracs.reduce((pre, mole_fracs, index) => {
              const { x, y } = points[index]
              pre.push({ x, y, mole_fracs })
              return pre
            }, [])
            this.viewVisible = true
          }
          if (!res.data) {
            this.$Notice.error({
              title: "错误",
              desc: "计算失败，请检查后端是否返回数据",
            });
          }
          this.spinShow = false;
        })
        .catch(function (error) {
          alert(error);
          this.spinShow = false;
        });
    },
    save() {
      console.log("保存参数plume", global.plumeTempJSON);
    },
  },
};
</script>
<style scoped>
.sider-tree {
  background: #fff;
  border: 1px solid #dcdee2;
  border-radius: 5px;
  margin: 15px 0 15px 15px;
  overflow-y: auto;
}

.tool-bar {
  background-color: #fff;
  margin: 15px;
  border-radius: 5px;
  border: 1px solid #dcdee2;
}

.content {
  margin: 0 15px 15px 15px;
  border: 1px solid #dcdee2;
  border-radius: 5px;
  background-color: #fff;
  overflow-y: auto;
  /* border: 1px solid #dcdee2;
  border-radius: 5px;
  background-color: #fff;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center; */
}

.img {
  /* left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
  max-width: 100%;
  max-height: calc(100% - 10px);
}

.gis-img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.gis-box {
  width: fit-content;
  margin: auto;
  position: relative
}

.gis-po {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #000;
  border: 2px solid #fff;
  padding: 3px;

  &::before {
    content: '';
    display: block;
    background: #fff;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
</style>
