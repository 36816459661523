<template>
  <div class="img-upload-wrap">
    <Button @click="addMap" type="warning" shape="circle">
      <svg class="icon" aria-hidden="true" style="width: 20px">
        <use xlink:href="#icon-daoru"></use>
      </svg>
      选择地图
    </Button>
    <Modal v-model="visible" title="GIS地图" :mask-closable="false" :closable="true" draggable sticky width="700">
      <Upload multiple type="drag" :show-upload-list="false" action="/api/c_sat/csat/upload" accept="image/*"
        :on-success="uploadSuccess">
        <div style="padding: 20px 0">
          <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
          <p>点击上传地图截图</p>
        </div>
      </Upload>
      <img style="width: 100%; height: 500px; object-fit: cover" ref="uploadImgViewRef" v-if="formData.src"
        :src="formData.src" alt="" srcset="" />
      <div style="margin-bottom: 20px;margin-top:20px">
        真实宽度（米）：
        <Input size="large" placeholder="请输入该图片对应的真实宽度" style="width: 81%" v-model="formData.trueWidth" />
      </div>
      <div>
        &nbsp; 旋转角度（°）：
        <Input size="large" placeholder="请输入旋转角度" style="width: 81%" v-model="formData.rotate" />
        <div style="margin-left:100px;margin-top:12px;">注：测试功能，建议填0</div>
      </div>
      <template #footer>
        <Button type="primary" size="large" long @click="submitMap">确定</Button>
      </template>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      formData: {
        src: '',
        trueWidth: '0',
        rotate: '0',
        meterPerPixel: '0'
      }
    }
  },
  methods: {
    addMap() {
      this.visible = true
    },
    init() {
      this.formData = {
        src: '',
        trueWidth: '0',
        rotate: '0',
        meterPerPixel: '0'
      }
    },
    submitMap() {
      const { trueWidth, rotate } = this.formData
      if (!trueWidth || !rotate) {
        this.$Message.error('请填写完整信息')
        return
      }
      this.formData.meterPerPixel = Number(trueWidth) / this.$refs.uploadImgViewRef.naturalWidth
      this.$emit('uploadSuccess', this.formData)
      this.visible = false
    },
    uploadSuccess(res, file) {
      this.formData.src = res.url;
    },
  }
}
</script>
<style scoped>
.img-upload-wrap {
  display: inline-block;
  margin-left: 16px;
}
</style>