<template>
    <Layout style="height: 100%">
        <Sider class="sider-tree" hide-trigger>
            <pllTree ref="pllTreeRef"></pllTree>
        </Sider>
        <Layout>
            <Header class="tool-bar">
                <Button type="success" @click="run()" shape="circle">
                    <svg class="icon" aria-hidden="true" style="width: 20px">
                        <use xlink:href="#icon-run-solid"></use>
                    </svg>
                    运行
                </Button>

                <FooterMsg></FooterMsg>
            </Header>
            <Content class="content">
                <Tabs value="name1" v-if="runed">
                    <TabPane label="风险指标" name="name0">
                        <div
                            align="center"
                            style="
                                height: 100%;
                                overflow-x: auto;
                            "
                        >
                            <div
                                class="table-title"
                                v-if="risk_metrics_data.length"
                            >
                                风险数据
                            </div>
                            <Table
                                v-if="risk_metrics_data.length"
                                :columns="risk_metrics_col"
                                :data="risk_metrics_data"
                                width="600"
                                border
                                size="small"
                            ></Table>
                            <Image
                                width="60%"
                                v-else
                                :src="require('@/assets/default-bg.png')"
                            />
                        </div>
                    </TabPane>
                    <TabPane label="事故场景排序" name="name5">
                        <div
                            align="center"
                            style="
                                height: 100%;
                                overflow-x: auto;
                            "
                        >
                            <div
                                class="table-title"
                                v-if="scenario_ranking_data.length"
                            >
                                事故场景排序
                            </div>
                            <Table
                                v-if="scenario_ranking_data.length"
                                :columns="scenario_ranking_col"
                                :data="scenario_ranking_data"
                                width="900"
                                border
                                size="small"
                            ></Table>
                            <Image
                                width="60%"
                                v-else
                                :src="require('@/assets/default-bg.png')"
                            />
                        </div>
                    </TabPane>
                    <TabPane label="割集" name="name1">
                        <div
                            align="center"
                            style="
            
                                height: 100%;
                                overflow-x: auto;
                            "
                        >
                            <template
                                v-if="
                                    data001.length ||
                                    data01.length ||
                                    data1.length ||
                                    data10.length ||
                                    data100.length
                                "
                            >
                                0.01% 泄漏口径
                                <Table
                                    :columns="columns001"
                                    :data="data001"
                                    width="600"
                                    border
                                    size="small"
                                ></Table>
                                0.1% 泄漏口径
                                <Table
                                    :columns="columns001"
                                    :data="data01"
                                    width="600"
                                    border
                                    size="small"
                                ></Table>
                                1% 泄漏口径
                                <Table
                                    :columns="columns001"
                                    :data="data1"
                                    width="600"
                                    border
                                    size="small"
                                ></Table>
                                10% 泄漏口径
                                <Table
                                    :columns="columns001"
                                    :data="data10"
                                    width="600"
                                    border
                                    size="small"
                                ></Table>
                                100% 泄漏口径
                                <Table
                                    :columns="columns001"
                                    :data="data100"
                                    width="600"
                                    border
                                    size="small"
                                ></Table>
                            </template>
                            <Image
                                width="60%"
                                v-else
                                :src="require('@/assets/default-bg.png')"
                            />
                        </div>
                    </TabPane>
                    <TabPane label="泄漏流量" name="name2">
                        <div
                            align="center"
                            style="
                
                                height: 100%;
                                overflow-x: auto;
                            "
                        >
                            <div
                                class="table-title"
                                v-if="leak_scenano_details_data.length"
                            >
                                泄漏场景详细信息
                            </div>
                            <Table
                                v-if="leak_scenano_details_data.length"
                                :columns="leak_scenano_details_col"
                                :data="leak_scenano_details_data"
                                width="600"
                                border
                                size="small"
                            ></Table>
                            <Image
                                width="60%"
                                v-else
                                :src="require('@/assets/default-bg.png')"
                            />
                        </div>
                    </TabPane>
                    <TabPane label="泄漏结果概率" name="name9">
                        <div
                            align="center"
                            style="
                      
                                height: 100%;
                                overflow-x: auto;
                            "
                        >
                            <div
                                class="table-title"
                                v-if="leak_outcome_data.length"
                            >
                                不同泄漏结果概率
                            </div>
                            <Table
                                v-if="leak_outcome_data.length"
                                :columns="leak_outcome_col"
                                :data="leak_outcome_data"
                                width="850"
                                border
                                size="small"
                            ></Table>
                            <Image
                                width="60%"
                                v-else
                                :src="require('@/assets/default-bg.png')"
                            />
                        </div>
                    </TabPane>
                    <TabPane label="热辐射" name="name3">
                        <div
                            align="center"
                            style="
                     
                                height: 100%;
                                overflow-x: auto;
                            "
                        >
                            <div
                                class="table-title"
                                v-if="position_data_data.length"
                            >
                                位置数据
                            </div>
                            <Table
                                v-if="position_data_data.length"
                                :columns="position_data_col"
                                :data="position_data_data"
                                width="900"
                                border
                                size="small"
                            ></Table>
                            <Image
                                width="60%"
                                v-else
                                :src="require('@/assets/default-bg.png')"
                            />
                        </div>
                    </TabPane>
                </Tabs>
                <Image
                    v-else
                    width="60%"
                    :src="require('@/assets/default-bg.png')"
                    style="
                        display: block;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    "
                />
            </Content>
        </Layout>
    </Layout>
    <Spin size="large" fix :show="spinShow"></Spin>
</template>
<script>
import FooterMsg from "../components/footer.vue";
import pllTree from "../components/pll.vue";
import global from "../assets/global.vue";
import http from "../assets/axiosInstance";
import object from "element-resize-detector/src/detection-strategy/object";

export default {
    data() {
        return {
            runed: false, //是否运行
            spinShow: false, //加载
            response: null,
            ///cut set
            columns001: [
                {
                    title: "割集",
                    key: "component",
                },
                {
                    title: "年度频率",
                    key: "frqs",
                },
            ],
            data001: [],
            data01: [],
            data1: [],
            data10: [],
            data100: [],
            /////////
            //scenano
            leak_outcome_col: [
                {
                    title: "场景结果",
                    key: "scenano_outcome",
                },
                {
                    title: "0.01% 泄漏口径",
                    key: "release001",
                },
                {
                    title: "0.1% 泄漏口径",
                    key: "release01",
                },
                {
                    title: "1% 泄漏口径",
                    key: "release1",
                },
                {
                    title: "10% 泄漏口径",
                    key: "release10",
                },
                {
                    title: "100% 泄漏口径",
                    key: "release100",
                },
            ],
            leak_outcome_data: [],
            leak_scenano_details_col: [
                {
                    title: "泄漏直径",
                    key: "leakSize",
                },
                {
                    title: "质量流率 (kg/s)",
                    key: "mass_flow_rate",
                },
            ],
            leak_scenano_details_data: [],
            position_data_col: [
                {
                    title: "位置",
                    key: "position",
                },
                {
                    title: "X (m)",
                    key: "x",
                },
                {
                    title: "Y (m)",
                    key: "y",
                },
                {
                    title: "Z (m)",
                    key: "z",
                },
                {
                    title: "0.01% 泄漏口径",
                    key: "leak_heat_flux_001",
                },
                {
                    title: "0.1% 泄漏口径",
                    key: "leak_heat_flux_01",
                },
                {
                    title: "1% 泄漏口径",
                    key: "leak_heat_flux_1",
                },
                {
                    title: "10% 泄漏口径",
                    key: "leak_heat_flux_10",
                },
                {
                    title: "100% 泄漏口径",
                    key: "leak_heat_flux_100",
                },
            ],
            position_data_data: [],
            risk_metrics_col: [
                {
                    title: "指标",
                    key: "col1",
                },
                {
                    title: "值",
                    key: "col2",
                },
            ],
            risk_metrics_data: [],
            scenario_ranking_col: [
                {
                    title: "事故场景",
                    key: "scenario",
                    sortable: true,
                },
                {
                    title: "事故场景结果",
                    key: "outcome",
                    sortable: true,
                },
                {
                    title: "年频率",
                    key: "average",
                    sortable: true,
                },
                {
                    title: "分支概率",
                    key: "branch",
                    sortable: true,
                },
                {
                    title: "风险贡献率",
                    key: "risk",
                    sortable: true,
                },
            ],
            scenario_ranking_data: [],
        };
    },
    created() {
        // http({
        //     url: "/checkToken",
        //     method: "post",
        //     data: {
        //         token: localStorage.getItem("token"),
        //         username: localStorage.getItem("name"),
        //     },
        // })
        //     .then((res) => {
        //         if (!res.data.available) {
        //             localStorage.clear();
        //             this.$router.push("/login");
        //         }
        //     })
        //     .catch(function (error) {});
    },
    components: { pllTree, FooterMsg },
    methods: {
        dataCheck() {
            let titleBefore = "pll 参数修正";
            console.log(
                `%c 🔵 ${titleBefore}`,
                "font-size:16px;background-color:#c4cbcf;color:#2486b9;font-weight:600;padding:15px;margin:15px;border-radius:5px"
            );
            let obj = global.pllTempJSON;
            for (let key in obj) {
                //console.log(key,obj[key])
                if (key == "System") {
                    this.dataSystemCheck(obj[key]);
                    let title = key + " 修正完成";
                    console.log(
                        `%c ${title}`,
                        "font-size:16px; color:#41ae3c;font-weight:600;"
                    );
                }
                if (key == "Components") {
                    this.dataComponentsCheck(obj[key]);
                    let title = key + " 修正完成";
                    console.log(
                        `%c ${title}`,
                        "font-size:16px; color:#41ae3c;font-weight:600;"
                    );
                }
                if (key == "Scene") {
                    this.dataSceneCheck(obj[key]);
                    let title = key + " 修正完成";
                    console.log(
                        `%c ${title}`,
                        "font-size:16px; color:#41ae3c;font-weight:600;"
                    );
                }
                if (key == "Fluid") {
                    this.dataFluidCheck(obj[key]);
                    let title = key + " 修正完成";
                    console.log(
                        `%c ${title}`,
                        "font-size:16px; color:#41ae3c;font-weight:600;"
                    );
                }
                if (key == "Vehicles") {
                    this.dataVehiclesCheck(obj[key]);
                    let title = key + " 修正完成";
                    console.log(
                        `%c ${title}`,
                        "font-size:16px; color:#41ae3c;font-weight:600;"
                    );
                }
                if (key == "Pipe_ManualLeak") {
                    this.dataPipe_ManualLeakCheck(obj[key]);
                    let title = key + " 修正完成";
                    console.log(
                        `%c ${title}`,
                        "font-size:16px; color:#41ae3c;font-weight:600;"
                    );
                }
                if (key == "Position") {
                    this.dataPositionCheck(obj[key]);
                    let title = key + " 修正完成";
                    console.log(
                        `%c ${title}`,
                        "font-size:16px; color:#41ae3c;font-weight:600;"
                    );
                }
                if (key == "CompLeakProb") {
                    this.dataCompLeakProbCheck(obj[key]);
                    let title = key + " 修正完成";
                    console.log(
                        `%c ${title}`,
                        "font-size:16px; color:#41ae3c;font-weight:600;"
                    );
                }
                if (key == "Ignition") {
                    this.dataIgnitionCheck(obj[key]);
                    let title = key + " 修正完成";
                    console.log(
                        `%c ${title}`,
                        "font-size:16px; color:#41ae3c;font-weight:600;"
                    );
                }
                if (key == "Overpressure") {
                    this.dataOverpressureCheck(obj[key]);
                    let title = key + " 修正完成";
                    console.log(
                        `%c ${title}`,
                        "font-size:16px; color:#41ae3c;font-weight:600;"
                    );
                }
                if (key == "Accident") {
                    this.dataAccidentCheck(obj[key]);
                    let title = key + " 修正完成";
                    console.log(
                        `%c ${title}`,
                        "font-size:16px; color:#41ae3c;font-weight:600;"
                    );
                }
            }
            let title = "全部数据修正成功，开始计算。";
            console.log(
                `%c 🟢 ${title}`,
                "font-size:16px;background-color:#eef7f2;color:#41ae3c;font-weight:600;padding:15px;margin:15px;border-radius:5px"
            );
        },
        dataAccidentCheck(obj) {
            for (let key in obj) {
                if (
                    key == "noz_po_dist" ||
                    key == "noz_ftc_dist" ||
                    key == "mvalve_ftc_dist" ||
                    key == "svalve_ftc_dist" ||
                    key == "svalve_ccf_dist" ||
                    key == "overp_dist" ||
                    key == "pvalve_fto_dist" ||
                    key == "driveoff_dist" ||
                    key == "coupling_ftc_dist"
                ) {
                    continue;
                }
                obj[key] = parseFloat(obj[key]);
                console.log("Accident-数据修正:", key);
            }
        },
        dataIgnitionCheck(obj) {
            let keyNameArr = Object.keys(obj);
            for (let i = 0; i < keyNameArr.length; i++) {
                if(keyNameArr[i]=='method'){
                    continue
                }
                let firstArr = obj[keyNameArr[i]];
                for (let j = 0; j < firstArr.length; j++) {
                    
                    firstArr[j] = parseFloat(firstArr[j]);
                }
                console.log("Ignition-数据修正:", keyNameArr[i]);
            }
        },
        dataOverpressureCheck(obj) {
            let keyNameArr = Object.keys(obj);
            for (let i = 0; i < keyNameArr.length; i++) {
                let firstArr = obj[keyNameArr[i]];
                for (let j = 0; j < firstArr.length; j++) {
                    firstArr[j] = parseFloat(firstArr[j]);
                }
                console.log("Overpressure-数据修正:", keyNameArr[i]);
            }
        },
        dataCompLeakProbCheck(obj) {
            let keyNameArr = Object.keys(obj);
            for (let i = 0; i < keyNameArr.length; i++) {
                let firstArr = obj[keyNameArr[i]];
                for (let j = 0; j < firstArr.length; j++) {
                    let secondArr = firstArr[j];
                    for (let k = 0; k < secondArr.length; k++) {
                        secondArr[k] = parseFloat(secondArr[k]);
                    }
                }
                console.log("CompLeakProb-数据修正:", keyNameArr[i]);
            }
        },
        dataPositionCheck(obj) {
            //console.log(obj.length)
            for (let i = 0; i < obj.length; i++) {
                for (let key in obj[i]) {
                    if (
                        key == "descrip" ||
                        key == "xdistr" ||
                        key == "ydistr" ||
                        key == "zdistr"
                    ) {
                        continue;
                    }
                    obj[i][key] = parseFloat(obj[i][key]);
                    console.log("Position", i, "-数据修正", key);
                }
            }
        },
        dataPipe_ManualLeakCheck(obj) {
            for (let key in obj) {
                obj[key] = parseFloat(obj[key]);
                console.log("Pipe_ManualLeak-数据修正:", key);
            }
        },
        dataFluidCheck(obj) {
            for (let key in obj) {
                if (
                    key == "rel_temp" ||
                    key == "rel_pres" ||
                    key == "amb_temp" ||
                    key == "amb_pres"
                ) {
                    obj[key] = parseFloat(obj[key]);
                    console.log("Fluid-数据修正:", key);
                }
            }
        },
        dataSystemCheck(obj) {
            for (let key in obj) {
                if (
                    key == "rand_seed" ||
                    key == "excl_radius" ||
                    key == "exposure_time"
                ) {
                    obj[key] = parseFloat(obj[key]);
                    console.log("System-数据修正:", key);
                }
            }
        },
        dataVehiclesCheck(obj) {
            for (let key in obj) {
                obj[key] = parseFloat(obj[key]);
                console.log("Vehicles-数据修正:", key);
            }
        },
        dataComponentsCheck(obj) {
            for (let key in obj) {
                obj[key] = parseFloat(obj[key]);
                console.log("Components-数据修正:", key);
            }
        },
        dataSceneCheck(obj) {
            for (let key in obj) {
                obj[key] = parseFloat(obj[key]);
                console.log("Scene-数据修正:", key);
            }
        },
        async run() {
            const { rel_phase, rel_pres } = this.$refs.pllTreeRef.form.Fluid;
            if (
                ["gas", "liquid"].includes(rel_phase) &&
                (Number(rel_pres) > 1296400 || Number(rel_pres) < 101325)
            ) {
                this.$Message.error(
                    "流体压力输入值应在101325Pa——1296400Pa之间"
                );
                return false;
            }
            const { pipe_outer_diam, pipe_thickness } =
                this.$refs.pllTreeRef.form.Pipe_ManualLeak;
            if (pipe_outer_diam <= 2 * pipe_thickness) {
                this.$Message.error("管道外径必须大于2倍管壁厚度");
                return false;
            }
            this.spinShow = true;
            //console.log("运行参数pll", global.pllTempJSON);
            this.dataCheck();
            http({
                url: "/c_sat/qra/qra",
                method: "post",
                data: global.pllTempJSON,
            })
                .then((res) => {
                    this.runed = true;
                    this.$Notice.success({
                        title: "pll模型计算成功！",
                    });
                    this.risk_metrics_data = [];
                    this.scenario_ranking_data = [];
                    this.data001 = [];
                    this.data01 = [];
                    this.data1 = [];
                    this.data10 = [];
                    this.data100 = [];
                    this.leak_scenano_details_data = [];
                    this.leak_outcome_data = [];
                    this.position_data_data = [];
                    //console.log("pll返回值", res);
                    this.response = res;
                    this.handleRanking(res.data.leak_results);
                    this.handleRiskMetrics(res.data);
                    this.handleCutSet(res.data);
                    this.handelScenanoDetails(res.data.leak_results);
                    this.handleThermalEffects(
                        res.data.position_qrads,
                        res.data.positions
                    );
                    this.spinShow = false;
                })
                .catch(function (error) {
                    alert(error);
                    this.spinShow = false;
                });
        },
        handleRanking(arr) {
            let outcomeArr = [
                "探测到泄漏并成功处理",
                "喷射火",
                "爆炸",
                "未点燃",
            ];
            for (let j = 0; j < 5; j++) {
                for (let i = 0; i < 4; i++) {
                    let averageMsg = "";
                    if (i == 0) {
                        let tempObj = {
                            scenario: arr[j].descrip.slice(0, 8) + "口径泄漏",
                            outcome: outcomeArr[i],
                            average:
                                arr[j].shutdown_avg_events.toExponential(4),
                            branch: arr[j].p_shutdown.toExponential(4),
                            risk: 0,
                        };
                        this.scenario_ranking_data.push(tempObj);
                    }
                    if (i == 1) {
                        let tempObj = {
                            scenario: arr[j].descrip.slice(0, 8) + "口径泄漏",
                            outcome: outcomeArr[i],
                            average: arr[j].jetfire_avg_events.toExponential(4),
                            branch: arr[j].p_jetfire.toExponential(4),
                            risk: arr[j].jetfire_pll_contrib.toExponential(4),
                        };
                        this.scenario_ranking_data.push(tempObj);
                    }
                    if (i == 2) {
                        let tempObj = {
                            scenario: arr[j].descrip.slice(0, 8) + "口径泄漏",
                            outcome: outcomeArr[i],
                            average: arr[j].explos_avg_events.toExponential(4),
                            branch: arr[j].p_explos.toExponential(4),
                            risk: arr[j].explos_pll_contrib.toExponential(4),
                        };
                        this.scenario_ranking_data.push(tempObj);
                    }
                    if (i == 3) {
                        let tempObj = {
                            scenario: arr[j].descrip.slice(0, 8) + "口径泄漏",
                            outcome: outcomeArr[i],
                            average: arr[j].no_ign_avg_events.toExponential(4),
                            branch: arr[j].p_no_ign.toExponential(4),
                            risk: 0,
                        };
                        this.scenario_ranking_data.push(tempObj);
                    }
                }
            }
        },
        handleRiskMetrics(res) {
            for (let i = 0; i < 3; i++) {
                if (i == 0) {
                    let tempObj = {
                        col1: "AIR",
                        col2: res.air.toExponential(4),
                    };
                    this.risk_metrics_data.push(tempObj);
                }
                if (i == 1) {
                    let tempObj = {
                        col1: "FAR",
                        col2: res.far.toExponential(4),
                    };
                    this.risk_metrics_data.push(tempObj);
                }
                if (i == 2) {
                    let tempObj = {
                        col1: "Total PLL",
                        col2: res.total_pll.toExponential(4),
                    };
                    this.risk_metrics_data.push(tempObj);
                }
            }
        },
        handleThermalEffects(arr_position_qrads, arr_position) {
            for (let i = 0; i < 9; i++) {
                let tempObj = {
                    position: i + 1,
                    x: arr_position[i][0].toExponential(4),
                    y: arr_position[i][1],
                    z: arr_position[i][2].toExponential(4),
                    leak_heat_flux_001:
                        arr_position_qrads[i][0].toExponential(4),
                    leak_heat_flux_01:
                        arr_position_qrads[i][1].toExponential(4),
                    leak_heat_flux_1: arr_position_qrads[i][2].toExponential(4),
                    leak_heat_flux_10:
                        arr_position_qrads[i][3].toExponential(4),
                    leak_heat_flux_100:
                        arr_position_qrads[i][4].toExponential(4),
                };
                this.position_data_data.push(tempObj);
            }
        },
        handelScenanoDetails(arr) {
            for (let i = 0; i < 5; i++) {
                let tempObj = {
                    leakSize: arr[i].descrip.slice(0, 8) + "口径泄漏",
                    mass_flow_rate: arr[i].mass_flow_rate.toExponential(4),
                };
                this.leak_scenano_details_data.push(tempObj);
                this.leak_outcome_data = [
                    {
                        scenano_outcome: "探测到泄漏并成功处理",
                        release001: arr[0].p_shutdown.toFixed(5),
                        release01: arr[1].p_shutdown.toFixed(5),
                        release1: arr[2].p_shutdown.toFixed(5),
                        release10: arr[3].p_shutdown.toFixed(5),
                        release100: arr[4].p_shutdown.toFixed(5),
                    },
                    {
                        scenano_outcome: "喷射火",
                        release001: (arr[0].p_jetfire ).toFixed(5),
                        release01: (arr[1].p_jetfire ).toFixed(5),
                        release1: (arr[2].p_jetfire ).toFixed(5),
                        release10: (arr[3].p_jetfire ).toFixed(5),
                        release100: (arr[4].p_jetfire ).toFixed(5),
                    },
                    {
                        scenano_outcome: "爆炸",
                        release001: (arr[0].p_explos ).toFixed(5),
                        release01: (arr[1].p_explos).toFixed(5),
                        release1: (arr[2].p_explos ).toFixed(5),
                        release10: (arr[3].p_explos ).toFixed(5),
                        release100: (arr[4].p_explos ).toFixed(5),
                    },
                    {
                        scenano_outcome: "未点燃",
                        release001: (
                            1 -
                            arr[0].p_jetfire  -
                            arr[0].p_explos  -
                            arr[0].p_shutdown
                        ).toFixed(5),
                        release01: (
                            1 -
                            arr[1].p_jetfire  -
                            arr[1].p_explos -
                            arr[1].p_shutdown
                        ).toFixed(5),
                        release1: (
                            1 -
                            arr[2].p_jetfire   -
                            arr[2].p_explos  -
                            arr[2].p_shutdown
                        ).toFixed(5),
                        release10: (
                            1 -
                            arr[3].p_jetfire -
                            arr[3].p_explos -
                            arr[3].p_shutdown
                        ).toFixed(5),
                        release100: (
                            1 -
                            arr[4].p_jetfire   -
                            arr[4].p_explos  -
                            arr[4].p_shutdown
                        ).toFixed(5),
                    },
                ];
            }
        },
        checkComponent(key) {
            switch (key) {
                case "arm":
                    key = "装卸臂";
                    break;
                case "compressor":
                    key = "压缩机";
                    break;
                case "exchanger":
                    key = "热交换机";
                    break;
                case "extra1":
                    key = "附件1";
                    break;
                case "extra2":
                    key = "附件2";
                    break;
                case "filter":
                    key = "过滤器";
                    break;
                case "flange":
                    key = "法兰";
                case "hose":
                    key = "软管";
                    break;
                case "instrument":
                    key = "设备";
                    break;
                case "joint":
                    key = "结合处";
                    break;
                case "pipe":
                    key = "管道";
                    break;
                case "valve":
                    key = "阀门";
                    break;
                case "vaporizer":
                    key = "冷凝器";
                    break;
                case "vessel":
                    key = "储罐";
                    break;
            }
            return key;
        },
        handleCutSet(res) {
            for (let i = 0; i < 5; i++) {
                let tempObj = res.leak_results[i].component_leak_freqs;
                for (var key of Object.keys(tempObj)) {
                    //console.log(key, tempObj[key]);
                    let keyDemo = this.checkComponent(key);
                    let componentObj = {
                        component: keyDemo,
                        frqs: tempObj[key],
                    };
                    if (i == 0) {
                        this.data001.push(componentObj);
                    }
                    if (i == 1) {
                        this.data01.push(componentObj);
                    }
                    if (i == 2) {
                        this.data1.push(componentObj);
                    }
                    if (i == 3) {
                        this.data10.push(componentObj);
                    }
                    if (i == 4) {
                        this.data100.push(componentObj);

                        //this.data100=this.data100.concat(tempArr100);
                        //console.log(tempArr100)
                    }
                }
            }
            let tempArr100 = [
                {
                    component: "充装超压导致破裂",
                    frqs: res.leak_results[4].f_overp_rupture,
                },
                {
                    component: "喷头泄漏",
                    frqs: res.leak_results[4].f_nozzle_release,
                },
                {
                    component: "驶离导致泄漏",
                    frqs: res.leak_results[4].f_driveoff,
                },
                {
                    component: "手动阀门关闭失效",
                    frqs: res.leak_results[4].f_mvalve_ftc,
                },
                {
                    component: "电磁阀门关闭失效",
                    frqs: res.leak_results[4].f_sol_valves_ftc,
                },
            ];
            for (let i = 0; i < 5; i++) {
                this.data100.push(tempArr100[i]);
            }
        },
        save() {
            console.log("保存参数pll", global.pllTempJSON);
        },
        submitComponents() {},
    },
};
</script>
<style scoped>
.sider-tree {
    background: #fff;
    border: 1px solid #dcdee2;
    border-radius: 5px;
    margin: 15px 0 15px 15px;
    overflow-y: auto;
}

.tool-bar {
    background-color: #fff;
    margin: 15px;
    border-radius: 5px;
    border: 1px solid #dcdee2;
}

.content {
    margin: 0 15px 15px 15px;
    border: 1px solid #dcdee2;
    border-radius: 5px;
    background-color: #fff;
    overflow-y: auto;
}

.table-title {
    font-size: 16px;
    color: #464c5b;
    font-weight: 500;
    margin: 20px;
}
</style>
