<script>
const pllJSON = {
  CompassRose: {
    initAngle: 0,
    frequencies: '0.125,0.125,0.125,0.125,0.125,0.125,0.125,0.125'
  },
  "Components": {
    "pipe_length": 20.0,
    "num_compressors": 0,
    "num_vessels": 0,
    "num_valves": 5,
    "num_instruments": 3,
    "num_joints": 35,
    "num_hoses": 1,
    "num_filters": 0,
    "num_flanges": 0,
    "num_exchangers": 0,
    "num_vaporizers": 0,
    "num_arms": 0,
    "num_extra_comp1": 0,
    "num_extra_comp2": 0
  },
  "System": {
    "rand_seed": 3632850,
    "excl_radius": 0.01,
    "nozzle_model": "yuce",
    "probit_thermal_id": "eise",
    "exposure_time": 30,
    "probit_rel_id": "head"
  },
  "Scene": {
    "X": 20.0,
    "Y": 5.0,
    "Z": 12.0
  },
  "Fluid": {
    "rel_temp": 287.79,
    "rel_pres": 35000000.0,
    "rel_phase": "none",
    "amb_temp": 287.79,
    "amb_pres": 101325.0
  },
  "Vehicles": {
    "num_vehicles": 20,
    "daily_fuelings": 2,
    "vehicle_days": 250
  },
  "Pipe_ManualLeak": {
    "pipe_outer_diam": 0.009525,
    "pipe_thickness": 0.001651,
    "detection_credit": 0.9,
    "rel_freq_000d01": -1,
    "rel_freq_000d10": -1,
    "rel_freq_001d00": -1,
    "rel_freq_010d00": -1,
    "rel_freq_100d00": -1,
    "fueling_fail_freq_override": -1
  },
  "Position": [
    {
      "count": 9,
      "descrip": "人员组1",
      "xdistr": "unif",
      "ydistr": "dete",
      "zdistr": "unif",
      "xa": 1,
      "xb": 20,
      "ya": 0,
      "yb": 0,
      "za": 1,
      "zb": 12,
      "hours": 2000
    }
  ],
  "CompLeakProb": {
    "compressor_leak_probs": [
      [-2.3053, 0.3018],
      [-4.0761, 0.5249],
      [-5.3881, 0.8212],
      [-8.7929, 0.7263],
      [-11.1359, 1.2067]
    ],
    "vessel_leak_probs": [
      [-13.477, 0.7347],
      [-13.641, 0.6387],
      [-14.0512, 0.6203],
      [-14.6144, 0.6041],
      [-15.2732, 0.6156]
    ],
    "valve_leak_probs": [
      [-5.8546, 0.25],
      [-7.4425, 0.4344],
      [-9.819, 1.1434],
      [-10.6079, 0.627],
      [-12.2436, 1.369]
    ],
    "instrument_leak_probs": [
      [-7.38, 0.71],
      [-8.54, 0.85],
      [-9.1, 0.92],
      [-9.21, 1.09],
      [-10.21, 1.49]
    ],
    "pipe_leak_probs": [
      [-11.7331, 0.7162],
      [-12.5079, 0.707],
      [-13.8601, 1.2515],
      [-14.5893, 1.1933],
      [-15.7354, 1.8486]
    ],
    "joint_leak_probs": [
      [-10.2591, 0.2423],
      [-12.2703, 0.8727],
      [-11.7538, 0.5333],
      [-11.7961, 0.6073],
      [-11.959, 0.66]
    ],
    "hose_leak_probs": [
      [-7.4534, 0.3863],
      [-8.5011, 0.605],
      [-8.7103, 0.6106],
      [-8.8001, 0.5901],
      [-9.6934, 0.9836]
    ],
    "filter_leak_probs": [
      [-5.2348, 1.6955],
      [-5.2822, 1.2885],
      [-5.3303, 1.2879],
      [-5.3798, 0.7448],
      [-5.4288, 0.8171]
    ],
    "flange_leak_probs": [
      [-3.9125, 1.492],
      [-6.1191, 1.1345],
      [-8.3252, 2.0541],
      [-10.5327, 0.7208],
      [-12.7385, 1.6925]
    ],
    "exchanger_leak_probs": [
      [999.0, 999.0],
      [999.0, 999.0],
      [999.0, 999.0],
      [999.0, 999.0],
      [999.0, 999.0]
    ],
    "vaporizer_leak_probs": [
      [999.0, 999.0],
      [999.0, 999.0],
      [999.0, 999.0],
      [999.0, 999.0],
      [999.0, 999.0]
    ],
    "arm_leak_probs": [
      [999.0, 999.0],
      [999.0, 999.0],
      [999.0, 999.0],
      [999.0, 999.0],
      [999.0, 999.0]
    ],
    "extra_comp1_leak_probs": [
      [999.0, 999.0],
      [999.0, 999.0],
      [999.0, 999.0],
      [999.0, 999.0],
      [999.0, 999.0]
    ],
    "extra_comp2_leak_probs": [
      [999.0, 999.0],
      [999.0, 999.0],
      [999.0, 999.0],
      [999.0, 999.0],
      [999.0, 999.0]
    ]
  },
  "Ignition": {
    "immed_ign_probs": [
      0.008, 0.053, 0.23
    ],
    "delayed_ign_probs": [
      0.004, 0.027, 0.12
    ],
    "ign_thresholds": [
      0.1250, 6.2500
    ],
    "method": 'dsb'
  },
  "Overpressure": {
    "peak_overp_list": [
      2500.0, 2500.0, 5000.0, 16000.0, 30000.0
    ],
    "overp_impulse_list": [
      250.0, 500.0, 1000.0, 2000.0, 4000.0
    ]
  },
  "Accident": {
    "noz_po_dist": "beta",
    "noz_po_a": 0.5,
    "noz_po_b": 610415.5,
    "noz_ftc_dist": "expv",
    "noz_ftc_a": 0.002,
    "noz_ftc_b": 0,
    "mvalve_ftc_dist": "expv",
    "mvalve_ftc_a": 0.001,
    "mvalve_ftc_b": 0,
    "svalve_ftc_dist": "expv",
    "svalve_ftc_a": 0.002,
    "svalve_ftc_b": 0,
    "svalve_ccf_dist": "expv",
    "svalve_ccf_a": 0.000128,
    "svalve_ccf_b": 0,
    "overp_dist": "beta",
    "overp_a": 3.5,
    "overp_b": 310289.5,
    "pvalve_fto_dist": "logn",
    "pvalve_fto_a": -11.74,
    "pvalve_fto_b": 0.67,
    "driveoff_dist": "beta",
    "driveoff_a": 31.5,
    "driveoff_b": 610384.5,
    "coupling_ftc_dist": "beta",
    "coupling_ftc_a": 0.5,
    "coupling_ftc_b": 5031
  }
}
const pllTempJSON = null

const plumeJSON = {
  "is_coordinate_adaptive": true,
  "amb_temperature": 288,
  "amb_pressure": 101325,
  "fluid_temperature": 288,
  "fluid_pressure": 35000000,
  "fluidPhase": "none",
  "orificeDiameter": 0.0035,
  "angle": 1.570796,
  "orificeDischargeCoefficient": 1,
  "nozzleModel": "yuce",
  "contours": 0.04,
  "x_min": -2.5,
  "x_max": 2.5,
  "y_min": 0,
  "y_max": 10
}
const plumeTempJSON = null

const jetflameJSON = {
  "amb_temperature": 288,
  "amb_pressure": 101325,
  "fluid_temperature": 287.8,
  "fluid_pressure": 35000000,
  "fluid_phase": "none",
  "orificeDiameter": 0.0035,
  "orificeAngle": 0.0,
  "orificeHeight": 0.0,
  "nozzleModel": "yuce",
  "rad_src_key": "multi",
  "humidity": 0.89,
  "XPOS": [
    0.01,
    0.5,
    1,
    2
  ],
  "YPOS": [
    1,
    1,
    1,
    1
  ],
  "ZPOS": [
    0.01,
    0.5,
    0.5,
    1
  ],
  "contours": [
    1.577,
    4.732,
    25.237
  ]
}
const jetflameTempJSON = null

const overpressureJSON = {
  amb_temperature: 288,
  amb_pressure: 101325,
  fluid_temperature: 287.8,
  fluid_pressure: 35000000,
  fluid_phase: "none",
  orificeDiameter: 0.0035,
  orificeAngle: 0.0,
  orificeDischargeCoefficient: 1,
  method: "tnt",
  nozzleModel: "yuce",
  bstFlameSpeed: "0.35",
  tntFactor: 0.03,
  XPOS: [0.01, 0.5, 1, 2, 0, 0, 0, 0, 0, 0],
  YPOS: [1, 1, 1, 1, 0, 0, 0, 0, 0, 0],
  ZPOS: [0.01, 0.5, 0.5, 1, 0, 0, 0, 0, 0, 0],
  contours: [
    3000,
    7000,
    11000,
    15000
  ]
}
const overPressureTempJSON = null

const accumulationJSON = {
  "amb_temperature": 288.0,
  "amb_pressure": 101325.0,
  "fluid_temperature": 287.8,
  "fluid_pressure": 35000000.0,
  "fluid_phase": "none",
  "orificeDiameter": 0.0035,
  "orificeAngle": 0.0,
  "orificeDischargeCoefficient": 1,
  "nozzleModel": "yuce",
  "tank_volume": 0.00363,
  "orificeHeight": 0.0,
  "enclosureHeight": 2.72,
  "floorCeilArea": 16.72,
  "ceilVent_Area": 0.090792,
  "ceilVent_Height": 2.42,
  "floorVent_Area": 0.00762,
  "floorVent_Height": 0.044,
  "times": [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29
  ],
  "ventVolumeFlowRate": 0.0,
  "wallDistance": 2.1255,
  "timeMax": 30,
  "point_Pressure": [
    13.8, 15, 55.2
  ],
  "point_Time": [
    1, 15, 20
  ],
  "line_Pressure": [
    13.8, 15, 55.2
  ]
}
const accumulationTempJSON = null

const singlePoolFireJSON = {
  "PoolShape": "Circle",
  "PoolDiameter": 35,
  "PoolLength": 1.81,
  "PoolWidth": 23.53,
  "FlameBaseHeight": 0,
  "TargetHeight": 0,
  "MolecularWeight": 17.0,
  "CombustionHeat": 50000000,
  "SpecificHeat": 1006,
  "NormalBoilingPoint": 112,
  "LiquidDensity": 432,
  "AmbientPressure": 14.7,
  "AmbientTemperature": 294.15,
  "WindSpeed": 8.55,
  "WindSpeedReferenceHeight": 10.0,
  "RelativeHumidity": 0.54,
  "RoughnessLength": 0.03,
  "VonKarmenConstant": 0.35,
  "InsolationType": "ClearNight",
  "FlameTemperature": 1300,
  "TestPointList": [],
  "TestFluxList": [25, 45]
}
const singlePoolFireTempJSON = null

const router = null
function toolNumber(num_str) {
  num_str = num_str.toString();
  if (num_str.indexOf("+") != -1) {
    num_str = num_str.replace("+", "");
  }
  if (num_str.indexOf("E") != -1 || num_str.indexOf("e") != -1) {
    var resValue = "",
      power = "",
      result = null,
      dotIndex = 0,
      resArr = [],
      sym = "";
    var numStr = num_str.toString();
    if (numStr[0] == "-") {
      // 如果为负数，转成正数处理，先去掉‘-’号，并保存‘-’.
      numStr = numStr.substr(1);
      sym = "-";
    }
    if (numStr.indexOf("E") != -1 || numStr.indexOf("e") != -1) {
      var regExp = new RegExp(
        "^(((\\d+.?\\d+)|(\\d+))[Ee]{1}((-(\\d+))|(\\d+)))$",
        "ig"
      );
      result = regExp.exec(numStr);
      if (result != null) {
        resValue = result[2];
        power = result[5];
        result = null;
      }
      if (!resValue && !power) {
        return false;
      }
      dotIndex = resValue.indexOf(".") == -1 ? 0 : resValue.indexOf(".");
      resValue = resValue.replace(".", "");
      resArr = resValue.split("");
      if (Number(power) >= 0) {
        var subres = resValue.substr(dotIndex);
        power = Number(power);
        //幂数大于小数点后面的数字位数时，后面加0
        for (var i = 0; i <= power - subres.length; i++) {
          resArr.push("0");
        }
        if (power - subres.length < 0) {
          resArr.splice(dotIndex + power, 0, ".");
        }
      } else {
        power = power.replace("-", "");
        power = Number(power);
        //幂数大于等于 小数点的index位置, 前面加0
        for (var i = 0; i < power - dotIndex; i++) {
          resArr.unshift("0");
        }
        var n = power - dotIndex >= 0 ? 1 : -(power - dotIndex);
        resArr.splice(n, 0, ".");
      }
    }
    resValue = resArr.join("");

    return sym + resValue;
  } else {
    return num_str;
  }
}

export default {
  router,
  pllJSON, pllTempJSON, //pll
  plumeJSON, plumeTempJSON,//plume
  jetflameJSON, jetflameTempJSON,//jetflame
  overpressureJSON, overPressureTempJSON,//overpressure
  accumulationJSON, accumulationTempJSON,//accumulation
  singlePoolFireJSON, singlePoolFireTempJSON,
  toolNumber
}
</script>